import * as React from 'react';

import { Divider, StyleSheet, css } from '@coursera/coursera-ui';

type Props = {
  text?: string;
};

const styles = StyleSheet.create({
  text: {
    backgroundColor: '#FFFFFF',
    color: '#616161',
    fontSize: '12px',
    lineHeight: '18px',
    padding: '0 10px',
  },
});

const Separator = ({ text }: Props) => (
  <Divider style={{ margin: '24px 0' }}>{text && <div {...css(styles.text)}>{text}</div>}</Divider>
);

export default Separator;
