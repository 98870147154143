/* @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';
import type { MutableRefObject } from 'react';
import Media from 'react-media';

import type { Theme } from '@coursera/cds-core';
import { useTheme } from '@coursera/cds-core';
import type { SectionName } from '@coursera/event-pulse-types';
import { useTracker, useVisibilityTracker } from '@coursera/event-pulse/react';

import type { PropsFromWithUserAgent } from 'bundles/page/common/withUserAgent';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import ProductOfferingCardDesktop from 'bundles/premium-hub/components/shared/ProductOfferingCardDesktop';
import ProductOfferingCardMobile from 'bundles/premium-hub/components/shared/ProductOfferingCardMobile';
import type { ProductOfferingCardLinkProps } from 'bundles/premium-hub/types';
import { removeProductIdPrefix } from 'bundles/premium-hub/utils/dataTransformationUtils';

import _t from 'i18n!nls/premium-hub';

type Props = ProductOfferingCardLinkProps & PropsFromWithUserAgent & { entityIndex: number; sectionName: SectionName };

const styles = {
  productLinkCard: (theme: Theme) => ({
    color: theme.palette.black[500],
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'none',
      color: theme.palette.black[500],
    },
    ':visited': {
      textDecoration: 'none',
      color: theme.palette.black[500],
    },
  }),
};

const ProductOfferingCard: React.FunctionComponent<Props> = ({
  entityIndex,
  userAgent,
  slug,
  url,
  title,
  partnerInfo,
  programDetails,
  programSummary,
  programDeadlineText,
  linkText,
  languageCode,
  name,
  sectionName,
  id,
}) => {
  const theme = useTheme();

  const eventProperties = {
    productCard: { index: entityIndex },
    product: { id: removeProductIdPrefix(id ?? ''), name: name ?? '', slug },
    pageSection: { sectionName },
  };

  const trackV3 = useTracker();
  const productCardRef: MutableRefObject<HTMLLIElement | null> = useVisibilityTracker(
    'view_product_card',
    eventProperties
  );

  if (!slug) {
    return null;
  }

  return (
    <li
      className="ProductOfferingCard"
      data-test="ProductOfferingCard"
      css={{ borderTop: '1px solid #e1e1e1' }}
      ref={productCardRef}
    >
      <TrackedLink2
        trackingName="product_card"
        data-test="product-card"
        data={{
          entityId: slug,
          entityIndex: entityIndex + 1,
        }}
        withVisibilityTracking={true}
        requireFullyVisible={true}
        href={url}
        hrefLang={languageCode}
        aria-label={_t(
          '#{productTitle} #{productOfferedBy}. Duration #{productDuration}. #{summary}. #{programDeadline}. #{linkText}',
          {
            productTitle: title,
            productOfferedBy: partnerInfo?.partnerText,
            productDuration: programDetails,
            summary: programSummary,
            programDeadline: programDeadlineText,
            linkText,
          }
        )}
        css={styles.productLinkCard(theme)}
        onClick={() => trackV3('click_product_card', eventProperties)}
      >
        <Media query={{ maxWidth: theme.breakpoints.values.lg }} defaultMatches={userAgent?.isMobileBrowser}>
          {(matches) =>
            matches ? (
              <ProductOfferingCardMobile
                title={title}
                partnerInfo={partnerInfo}
                programDetails={programDetails}
                programSummary={programSummary}
                programDeadlineText={programDeadlineText}
                linkText={linkText}
              />
            ) : (
              <ProductOfferingCardDesktop
                title={title}
                partnerInfo={partnerInfo}
                programDetails={programDetails}
                programSummary={programSummary}
                programDeadlineText={programDeadlineText}
                linkText={linkText}
              />
            )
          }
        </Media>
      </TrackedLink2>
    </li>
  );
};

export default ProductOfferingCard;
