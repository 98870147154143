import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('checkboxAndRadio', [
  'label',
  'icon',
  'input',
  'checked',
  'indeterminate',
  'disabled',
  'labelText',
  'labelContent',
  'labelSuffix',
  'supportText',
  'focusVisible',
  'hasIcon',
  'success',
  'error',
  'nonInteractiveSpace',
]);

const getRadioAndCheckboxCss = css`
  --input-fill: var(--cds-color-neutral-background-primary);
  --input-fill--hover: var(
    --cds-color-interactive-background-primary-hover-weak
  );
  --input-fill--active: var(
    --cds-color-interactive-background-primary-pressed-weak
  );
  --input-color: var(--cds-color-neutral-primary-weak);
  --input-color--hover: var(--cds-color-interactive-primary-hover);
  --input-color--active: var(--cds-color-interactive-primary-pressed);
  --label-text-color: var(--cds-color-neutral-primary);
  --label-text-color--hover: var(--cds-color-interactive-primary-hover);
  --label-text-color--active: var(--cds-color-interactive-primary-pressed);
  --support-text-color: var(--cds-color-neutral-primary-weak);

  &.${classes.checked}, &.${classes.indeterminate} {
    --label-text-color: var(--cds-color-interactive-primary);
    --input-color: var(--cds-color-interactive-primary);
    --input-fill: var(--cds-color-interactive-primary);
    --input-fill--hover: var(--cds-color-interactive-primary-hover);
    --input-fill--active: var(--cds-color-interactive-primary-pressed);
  }

  &.${classes.success} {
    &.${classes.checked}, &.${classes.indeterminate} {
      --input-fill: var(--cds-color-feedback-success);
      --input-fill--hover: var(--cds-color-feedback-success-hover);
    }
    --input-fill--hover: var(--cds-color-feedback-background-success-weak);
    --input-color: var(--cds-color-feedback-success);
    --input-color--hover: var(--cds-color-feedback-success-hover);
    --label-text-color: var(--cds-color-feedback-success);
    --label-text-color--hover: var(--cds-color-feedback-success-hover);
  }

  &.${classes.error} {
    &.${classes.checked}, &.${classes.indeterminate} {
      --input-fill: var(--cds-color-feedback-error);
      --input-fill--hover: var(--cds-color-feedback-error-hover);
    }
    --input-fill--hover: var(--cds-color-feedback-background-error-weak);
    --input-color: var(--cds-color-feedback-error);
    --input-color--hover: var(--cds-color-feedback-error-hover);
    --label-text-color: var(--cds-color-feedback-error);
    --label-text-color--hover: var(--cds-color-feedback-error-hover);
  }

  &.${classes.disabled} {
    --input-fill: var(--cds-color-neutral-disabled);
    --input-fill--hover: var(--cds-color-neutral-disabled);
    --input-fill--active: var(--cds-color-neutral-disabled);
    --input-color: var(--cds-color-neutral-disabled-strong);
    --input-color--hover: var(--cds-color-neutral-disabled-strong);
    --input-color--active: var(--cds-color-neutral-disabled-strong);
    --label-text-color: var(--cds-color-neutral-disabled-strong);
    --label-text-color--hover: var(--cds-color-neutral-disabled-strong);
    --label-text-color--active: var(--cds-color-neutral-disabled-strong);
    --support-text-color: var(--cds-color-neutral-disabled-strong);

    &.${classes.checked} {
      --input-fill: var(--cds-color-neutral-disabled-strong);
      --input-fill--hover: var(--cds-color-neutral-disabled-strong);
      --input-fill--active: var(--cds-color-neutral-disabled-strong);
    }
  }

  .${classes.label} {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--cds-spacing-150);
    padding: var(--cds-spacing-100) 0;
    cursor: pointer;
    word-break: break-word;

    .${classes.input} {
      fill: var(--input-fill);
      color: var(--input-color);
      padding: 0;

      & input + svg {
        fill: inherit;
      }
    }

    .${classes.icon} {
      width: var(--cds-spacing-300);
      height: var(--cds-spacing-300);
    }

    .${classes.labelText} {
      display: flex;
      width: 100%;
      color: var(--label-text-color);

      .${classes.labelContent} {
        flex: 1;
      }

      .${classes.labelSuffix} {
        padding-left: var(--cds-spacing-100);
        margin-left: auto;
        white-space: nowrap;
      }
    }

    .${classes.focusVisible} {
      color: var(--label-text-color--hover);

      & + .${classes.labelText} {
        color: var(--label-text-color--hover);
      }
    }

    &:hover {
      .${classes.input} {
        fill: var(--input-fill--hover);
        color: var(--input-color--hover);
      }

      .${classes.labelText} {
        color: var(--label-text-color--hover);
      }
    }
  }

  :not(.${classes.success}, .${classes.error}) {
    .${classes.label}:active {
      .${classes.input} {
        fill: var(--input-fill--active);
        color: var(--input-color--active);
      }

      .${classes.labelText} {
        color: var(--label-text-color--active);
      }
    }
  }

  &.${classes.disabled} .${classes.label} {
    cursor: default;
  }

  .${classes.supportText} {
    --support-text-offset: calc(
      var(--cds-spacing-300) + var(--cds-spacing-150)
    );

    margin-top: calc(var(--cds-spacing-50) * -1);
    margin-bottom: var(--cds-spacing-100);
    margin-left: var(--support-text-offset);
    color: var(--support-text-color);

    &.${classes.hasIcon} {
      margin-left: calc(var(--support-text-offset) * 2);
    }
  }

  .${classes.nonInteractiveSpace} {
    margin-bottom: var(--cds-spacing-100);
    margin-left: calc(var(--cds-spacing-300) + var(--cds-spacing-150));
  }
`;

export default getRadioAndCheckboxCss;
