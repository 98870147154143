/** @jsx jsx */
// do not remove this import: `css` is needed to set `newFeatureCSS` below
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { IconButton, type IconButtonProps } from '@coursera/cds-core';
import type { CourseItem, Product } from '@coursera/event-pulse-types';

import CoachIcon from 'bundles/ai-course-coach/assets/CoachIcon';
import { useNewFeatureStyles } from 'bundles/ai-course-coach/utils/newFeaturesUtils';
import { useCoachTrackingContext } from 'bundles/ai-course-coach/utils/tracking';
import { useCourseToolBarPlugins } from 'bundles/course-v2/components/navigation/CourseToolBar/useCourseToolBarPlugins';
import { useTracker, useVisibilityTracker } from 'bundles/page/lib/event-pulse/react';

import _t from 'i18n!nls/learning-assistant-chat-ai';

const styles = {
  root: (isSelected: boolean) => css`
    margin-right: var(--cds-spacing-100);

    ${isSelected &&
    `
      .cds-button-label {
        color: var(--cds-color-blue-600);
      }

      background-color: var(--cds-color-emphasis-primary-background-xweak);
      border-radius: var(--cds-border-radius-100);
    `}
  `,
};

type Props = {
  size?: IconButtonProps['size'];
};

const ExternalCoachLauncher: React.FC<Props> = (props) => {
  const [coachEnabled, setEnabled] = useState(false);
  const [coachAnimationState, setCoachAnimationState] = useState<'default' | 'wink'>('default');
  const { plugins, toolSelected, setToolSelected, expanded, setExpanded } = useCourseToolBarPlugins();

  const [shouldShowFeatureCSS, setShowFeatureCSS] = useState<boolean>(false);

  const { newFeatureCSS, newFeatureInteractedCallback } = useNewFeatureStyles({
    feature: 'ExternalCoachLauncher',
    animation: 'bounce',
  });

  const { size = 'medium' } = props;

  const track = useTracker();
  const trackingContext = useCoachTrackingContext()?.data;

  const sharedEventingData = useMemo(
    () => ({
      product: {
        type: 'course',
        id: trackingContext?.courseId,
      } as Product,
      courseItem: {
        id: trackingContext?.itemId,
      } as CourseItem,
    }),
    [trackingContext]
  );

  const launcherVisibilityTrackingRef: React.MutableRefObject<HTMLButtonElement | null> = useVisibilityTracker(
    'view_coach',
    {
      coachViewType: 'view_coach_tool_launcher',
      coach: {
        mode: trackingContext?.mode,
        location: 'toolbar',
      },
      ...(trackingContext?.courseId && { product: sharedEventingData.product }),
      ...(trackingContext?.itemId && { courseItem: sharedEventingData.courseItem }),
    }
  );

  useEffect(() => {
    const handleChatClose = () => {
      setShowFeatureCSS(true);
    };

    if (expanded) {
      // reset when expanded for next time
      setShowFeatureCSS(false);
    }

    if (coachEnabled) {
      document.body.addEventListener('coachChatClosed', handleChatClose);
    }

    return () => {
      document.body.removeEventListener('coachChatClosed', handleChatClose);
    };
  }, [coachEnabled, expanded]);

  // if coach plugin registered enable coach
  useEffect(() => {
    if (plugins === null) {
      return;
    }
    const coachPlugin = plugins.find((plugin) => plugin.name === 'coach');
    if (coachPlugin) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [plugins]);

  const handleMouseEnter = useCallback(() => {
    setCoachAnimationState('wink');
  }, []);
  const handleFocus = useCallback(() => {
    setCoachAnimationState('wink');
  }, []);

  const handleMouseLeave = useCallback(() => {
    setCoachAnimationState('default');
  }, []);
  const handleBlur = useCallback(() => {
    setCoachAnimationState('default');
  }, []);

  const handleClick = () => {
    newFeatureInteractedCallback();

    if (toolSelected === 'coach') {
      setExpanded(!expanded);
    } else {
      setToolSelected('coach');
      setExpanded(true);
    }

    track('interact_coach', {
      coachInteractionType: expanded ? 'close_coach_tool' : 'launch_coach_tool',
      coach: {
        mode: trackingContext?.mode,
        location: 'toolbar',
      },
      ...(trackingContext?.courseId && { product: sharedEventingData.product }),
      ...(trackingContext?.itemId && { courseItem: sharedEventingData.courseItem }),
    });
  };

  const currentIconState = coachEnabled ? coachAnimationState : 'disabled';
  const isSelected = coachEnabled && expanded;

  return (
    <IconButton
      ref={launcherVisibilityTrackingRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onClick={handleClick}
      variant="ghost"
      aria-disabled={!coachEnabled} // `aria-disabled` allows users to view tooltip while in disabled state
      icon={<CoachIcon state={currentIconState} size={size} />}
      tooltip={coachEnabled ? _t('Chat with Coursera Coach') : _t('Coursera Coach is not available on this page.')}
      aria-label={_t('Chat with #{coachLabel}, your AI-powered learning assistant', { coachLabel: 'Coach' })}
      aria-pressed={expanded}
      data-testid="tool-button-coach"
      css={[styles.root(isSelected), shouldShowFeatureCSS && newFeatureCSS]}
    />
  );
};

export default ExternalCoachLauncher;
