import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('standaloneCheckbox', [
  'validationLabel',
]);

const getStandAloneCheckboxCss = css`
  .${classes.validationLabel} {
    max-width: 100%;
    width: max-content;
    margin-bottom: var(--cds-spacing-50);
  }
`;

export default getStandAloneCheckboxCss;
