/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { memo } from 'react';

import { snakeCase } from 'lodash';

import Retracked from 'js/app/retracked';
import { useRetracked } from 'js/lib/retracked';

import { Button, Chip, VisuallyHidden, typography2 } from '@coursera/cds-core';

import TrackedButton from 'bundles/page/components/TrackedButton';
import { FILTERS_CONFIG } from 'bundles/search-common/components/filters/getFiltersList';
import { IS_PART_OF_COURSERA_PLUS, LANGUAGE_FILTER_KEYS } from 'bundles/search-common/constants/filter';
import { useOwnsCourseraLite } from 'bundles/search-common/providers/LearnerProvider';
import { getSearchFilterOptionItemTranslations } from 'bundles/search-common/utils/getFilterItemsTranslations';

import _t from 'i18n!nls/search-common';

const styles = {
  activeFiltersContainer: css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
  `,
  chip: () => css`
    padding: 0 var(--cds-spacing-150) !important;
    margin: 0 var(--cds-spacing-100) var(--cds-spacing-100) 0 !important;
  `,
  clearAllButton: css`
    padding: 6px 12px;
    text-decoration: underline;
    color: var(--cds-color-grey-500);
    ${typography2.bodySecondary};
    margin-right: 10px;
    margin-bottom: 8px;

    &:hover {
      background: none;
    }
  `,
};

type Props = {
  removeFilter: (filter: string) => void;
  clearFilters: () => void;
  items: string[];
};

type PropsFromCaller = Props & {
  eventingData?: { searchIndex: string; searchIndexPosition: number };
};

export const ActiveFilterItems = ({ items = [], removeFilter, clearFilters }: Props) => {
  const [filterClearedMsg, setFilterClearedMsg] = React.useState('');
  const prevItemCount = React.useRef(items.length);
  const trackComponent = useRetracked();
  const isSubscribedToCourseraLite = useOwnsCourseraLite();

  const getFilterItemDisplayValue = (category: string, translatedLabel: string) => {
    const isCourseraLiteFilter = isSubscribedToCourseraLite && category === IS_PART_OF_COURSERA_PLUS;
    const translatedCategoryName = FILTERS_CONFIG[category].getName(isCourseraLiteFilter);
    if ([IS_PART_OF_COURSERA_PLUS].includes(category)) {
      return translatedCategoryName;
    } else if (LANGUAGE_FILTER_KEYS.includes(category)) {
      return `${translatedCategoryName}: ${translatedLabel}`;
    }
    return translatedLabel;
  };

  React.useEffect(() => {
    if (prevItemCount.current > 0 && items.length === 0) {
      setFilterClearedMsg(_t('All filters cleared'));
    } else if (prevItemCount.current === 0 && items.length > 0) {
      setFilterClearedMsg('');
    }
    prevItemCount.current = items.length;
  }, [items]);

  const onClick = (item: string) => {
    const [category, label] = item.split(':');
    trackComponent({
      trackingName: snakeCase(`filter_${category}_unchecked`),
      trackingData: { label },
      action: 'click',
    });
    removeFilter(item);
  };

  return (
    <>
      <VisuallyHidden>
        <div aria-live="polite">{filterClearedMsg}</div>
      </VisuallyHidden>
      {items.length > 0 && (
        <div className="rc-ActiveFilterItems" css={styles.activeFiltersContainer} data-testid="active-filter-items">
          {items.map((item: string) => {
            const [category, label] = item.split(':');
            const optionItemTranslations = getSearchFilterOptionItemTranslations()[category];
            const translatedItem = optionItemTranslations?.[label];
            const translatedLabel = typeof translatedItem !== 'string' ? translatedItem?.title : translatedItem;
            const itemDisplayValue = getFilterItemDisplayValue(category, translatedLabel || label);
            return (
              <Chip
                css={styles.chip}
                key={item}
                variant="delete"
                aria-label={_t('Clear #{filterItem} filter', {
                  filterItem: itemDisplayValue,
                })}
                onClick={() => onClick(item)}
              >
                {itemDisplayValue}
              </Chip>
            );
          })}
          <Button
            css={styles.clearAllButton}
            data-test="clear-all-button"
            trackingName="clear_all_button"
            withVisibilityTracking={false}
            requireFullyVisible={false}
            component={TrackedButton}
            variant="ghost"
            onClick={clearFilters}
            aria-label={_t('Clear all filters')}
          >
            {_t('Clear all')}
          </Button>
        </div>
      )}
    </>
  );
};

export default memo(
  Retracked.createTrackedContainer((props: PropsFromCaller) => {
    return {
      indexName: props.eventingData?.searchIndex,
      indexPosition: props.eventingData?.searchIndexPosition || 0,
    };
  })(ActiveFilterItems)
);
