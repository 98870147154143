import { useLocation, useNavigate } from 'js/lib/useRouter';

import { SHOW_TERMS_OF_USE_ACCEPT_PARAM } from 'bundles/marketing-consent/constants';

const useRemoveTermsOfUseAcceptParam = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const removeTermsOfUseAcceptParam = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete(SHOW_TERMS_OF_USE_ACCEPT_PARAM);
    const searchParams = queryParams.toString();
    navigate({ pathname: location.pathname, search: searchParams ? `?${searchParams}` : undefined }, { replace: true });
  };

  return removeTermsOfUseAcceptParam;
};

export default useRemoveTermsOfUseAcceptParam;
