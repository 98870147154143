import React from 'react';

import { useId } from '@coursera/cds-common';

import type { BaseCheckboxProps } from '@core/forms';
import { FormControl, BaseCheckbox } from '@core/forms';
import { FormValidationLabel } from '@core/forms/FormValidationLabel';
import { useControlled } from '@core/utils';

import getStandaloneCheckboxCss, { classes } from './getStandaloneCheckboxCss';

export type Props = {
  /**
   * CSS class applied to the root element
   */
  className?: string;
  /**
   * Defines whether to render optional text next to the label
   */
  optional?: boolean;
  /**
   * Validation label for the standalone checkbox.
   */
  validationLabel?: string;
  /**
   * The initial checked value. Use when the component is not controlled.
   */
  defaultChecked?: boolean;
} & Omit<BaseCheckboxProps, 'showOptionalMark' | 'required' | 'indeterminate'>;

/**
 * Standalone Checkbox allows the user to opt in/out of an option.
 *
 * See [Props](__storybookUrl__/components-inputs-checkbox--default#props)
 */
const StandaloneCheckbox = React.forwardRef(function StandaloneCheckbox(
  props: Props,
  ref: React.Ref<HTMLDivElement>
): React.ReactElement<Props> {
  const {
    id: idFromProps,
    className,
    optional,
    validationStatus,
    validationLabel,
    inputProps: inputPropsFromProps,
    defaultChecked = false,
    checked: checkedFromProps,
    onChange,
    ...rest
  } = props;

  const [checked, setChecked] = useControlled<boolean>({
    default: defaultChecked,
    controlled: checkedFromProps,
    name: 'StandaloneCheckbox',
    state: 'checked',
  });

  const handleChange: BaseCheckboxProps['onChange'] = (event, value) => {
    setChecked(value);
    onChange?.(event, value);
  };

  const id = useId(idFromProps);

  const validationLabelId = `${id}-validation`;

  const ariaDescribedBy = [
    validationLabelId,
    inputPropsFromProps?.['aria-describedby'],
  ]
    .filter((id) => id !== undefined)
    .join(' ');

  const inputProps =
    validationStatus && validationLabel
      ? {
          ...inputPropsFromProps,
          'aria-describedby': ariaDescribedBy,
        }
      : inputPropsFromProps;

  return (
    <FormControl
      ref={ref}
      className={className}
      css={getStandaloneCheckboxCss}
      id={id}
    >
      {validationStatus && validationLabel && (
        <FormValidationLabel
          className={classes.validationLabel}
          id={validationLabelId}
          label={validationLabel}
          validationStatus={validationStatus}
        />
      )}
      <BaseCheckbox
        checked={checked}
        id={`${id}-base`}
        inputProps={inputProps}
        required={!optional}
        showOptionalMark={optional}
        validationStatus={validationStatus}
        onChange={handleChange}
        {...rest}
      />
    </FormControl>
  );
});

export default StandaloneCheckbox;
