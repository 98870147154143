/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { forwardRef, useCallback, useLayoutEffect, useRef, useState } from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Link, StandaloneCheckbox, Typography2 } from '@coursera/cds-core';
import type { StandaloneCheckboxProps } from '@coursera/cds-core';

import _t from 'i18n!nls/authentication';

type Props = Omit<StandaloneCheckboxProps, 'label'> & {
  'data-testid'?: string;
};

const TermsOfUseCheckbox = forwardRef<HTMLInputElement, Props>(({ onChange, ...props }: Props, ref) => {
  const isControlledRef = useRef<boolean>(props.value !== undefined);
  const [state, setState] = useState(props.checked ?? false);
  const [value, setValue] = useState(props.value ?? '');

  const checked = (isControlledRef.current ? props.checked : state) ?? false;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
      if (!isControlledRef.current) {
        setState(event.target.checked);
      }
      setState(event.target.checked);
      setValue(String(event.target.checked));

      onChange?.(event, isChecked);
    },
    [onChange]
  );

  useLayoutEffect(() => {
    isControlledRef.current = props.value !== undefined;
  }, [props.value]);

  const message = (
    <Typography2 variant="bodyPrimary" component="p">
      <FormattedMessage
        message={_t(`By clicking "Join for Free", I accept Coursera's {tosLink} and {privacyLink}.`)}
        privacyLink={
          <Link
            href="/about/privacy"
            target="_blank"
            css={css`
              text-decoration: underline !important;
            `}
          >
            {_t('Privacy Notice')}
          </Link>
        }
        tosLink={
          <Link
            href="/about/terms"
            target="_blank"
            css={css`
              text-decoration: underline !important;
            `}
          >
            {_t('Terms of Use')}
          </Link>
        }
      />
    </Typography2>
  );

  return (
    <StandaloneCheckbox
      name="touCheckbox"
      onChange={handleChange}
      checked={checked}
      value={value}
      //@ts-expect-error Type 'Element' is not assignable to type 'string'.ts(2322)
      label={message}
      ref={ref}
      {...props}
    />
  );
});

export default TermsOfUseCheckbox;
