/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import classNames from 'classnames';
import URI from 'jsuri';
import { forEach } from 'lodash';

import createLoadableComponent from 'js/lib/createLoadableComponent';
import { useRetracked } from 'js/lib/retracked';
import { useLocation } from 'js/lib/useRouter';
import { get } from 'js/lib/user';

import type { Theme } from '@coursera/cds-core';
import { IconButton, Popover } from '@coursera/cds-core';
import { ChevronDownIcon, ChevronUpIcon } from '@coursera/cds-icons';
import { useTracker } from '@coursera/event-pulse/react';

import { useNaptime } from 'bundles/naptimejs';
import type { DegreesV1 } from 'bundles/naptimejs/resources/__generated__/DegreesV1';
import type EnterpriseProgramsV1 from 'bundles/naptimejs/resources/enterprisePrograms.v1';
import ThirdPartyOrganizationV1 from 'bundles/naptimejs/resources/thirdPartyOrganizations.v1';
import EnterpriseProgramLogo from 'bundles/page-header/components/EnterpriseProgramLogo';
import type { Course } from 'bundles/page-header/components/PageHeader';
import ThirdPartyOrganizationLogo from 'bundles/page-header/components/ThirdPartyOrganizationLogo';
import type { ThirdPartyOrganization } from 'bundles/page-header/components/ThirdPartyOrganizationLogo';
import UniversityLogo from 'bundles/page-header/components/UniversityLogo';
import ProgramSwitcherPlaceholder from 'bundles/page-header/components/program-switcher/ProgramSwitcherPlaceholder';
import { usePageHeader } from 'bundles/page-header/contexts/PageHeaderContext';
import CourseraLogo from 'bundles/page/components/CourseraLogo';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import { TEAMS_LOGO_URL } from 'bundles/teams-v2/constants';

import _t from 'i18n!nls/page';

const LoadableProgramSwitcher = createLoadableComponent(
  () => import('bundles/page-header/components/program-switcher/ProgramSwitcher'),
  ProgramSwitcherPlaceholder
);

const dusk800 = '#7a7d7f';

const styles = {
  headerLogoWrapper: (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .rc-CourseraLogo {
      width: 127px;
      height: 32px;
    }

    .rc-CourseraLogo.team-logo {
      width: 237px;
      height: 32px;
    }

    .rc-CourseraLogo.coursera-plus-logo {
      width: 177px;
      height: 20px;
      transform: translateY(5px);
    }

    .header-logo {
      min-height: 30px;
      max-height: 32px;
      max-width: 200px;
      min-width: 30px;

      @media (min-width: 1200px) {
        max-width: 350px;
      }
    }

    .header-pipe {
      margin: 8px 16px;
      height: 26px;
      border-left: 1px solid ${theme.palette.gray[500]};
    }
  `,
  logoContainer: css`
    padding: 0 var(--cds-spacing-200) 0 0 !important;
  `,
  switcherButton: (theme: Theme) => css`
    margin-left: 10px !important;
    min-height: 24px;
    min-width: 24px !important;

    :hover {
      text-decoration: none !important;
      color: ${theme.palette.blue[600]};
    }
  `,
  switcherWrapper: (theme: Theme) => css`
    height: 100%;
    line-height: 0;
    border-radius: 16px;
    border: 1px solid ${theme.palette.gray[300]};

    :hover {
      border: 1px solid ${theme.palette.gray[500]};
    }

    .switcher-svg {
      height: 10px;
      width: 10px;
      margin: 4px;

      :hover {
        color: ${theme.palette.blue[600]};
      }
    }
  `,
  centerWrapper: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  padding: css`
    padding-top: 8px;
  `,
  menuSwitcherIcon: css`
    padding: 10px;
    margin-right: var(--cds-spacing-100);
  `,
  navbarHeaderContainer: css`
    .bt3-navbar-header::before,
    .bt3-navbar-header::after {
      content: ' ';
      display: table;
    }

    .bt3-navbar-header::after {
      clear: both;
    }

    @media (min-width: 768px) {
      .bt3-navbar-header {
        float: left;
      }
    }

    .bt3-container > .bt3-navbar-header,
    .bt3-container-fluid > .bt3-navbar-header {
      margin-right: -15px;
      margin-left: -15px;
    }

    @media (min-width: 768px) {
      .bt3-container > .bt3-navbar-header,
      .bt3-container-fluid > .bt3-navbar-header {
        margin-right: 0;
        margin-left: 0;
      }
    }
  `,
};

type PropsFromNaptime = {
  thirdPartyOrganization?: ThirdPartyOrganization | null;
};

type PropsFromCaller = {
  course?: Course;
  program?: EnterpriseProgramsV1;
  degree?: DegreesV1;
  affiliateElement?: React.ReactElement | null;
  hexColorCode?: string;
  disableUserInteraction?: boolean;
  enableCourseraLogoOnly?: boolean;
  handleProgramSwitcherToggle?: () => void;
  isOpened?: boolean;
  displaySwitcher?: boolean;
  showEnterpriseLogo?: boolean;
  logoWrapper?: string;
  logoQueryParams?: Record<string, string | undefined>;
  isAdminOrTeachPage?: boolean;
  isOrgHome?: boolean;
  programLogoDisplay?: EnterpriseProgramsV1;
  isLoggedInHome?: boolean;
  thirdPartyOrganizationId?: string | null;
  degrees?: DegreesV1[];
  programs?: EnterpriseProgramsV1[];
};

const HeaderLogo = (props: PropsFromCaller) => {
  const {
    hexColorCode = dusk800,
    disableUserInteraction,
    enableCourseraLogoOnly,
    handleProgramSwitcherToggle,
    isOpened,
    displaySwitcher,
    showEnterpriseLogo,
    logoQueryParams,
    program,
    degree,
    course,
    affiliateElement,
    logoWrapper = 'div',
    isAdminOrTeachPage,
    isOrgHome,
    programLogoDisplay,
    isLoggedInHome,
    degrees,
    programs,
  } = props;

  const {
    data: { thirdPartyOrganization },
  } = useNaptime<PropsFromNaptime>(
    () => ({
      thirdPartyOrganization: props.thirdPartyOrganizationId
        ? ThirdPartyOrganizationV1.get(props.thirdPartyOrganizationId, {
            fields: ['rectangularLogo', 'squareLogo', 'name', 'primaryColor', 'iconColor'],
          })
        : null,
    }),
    [props.thirdPartyOrganizationId]
  );

  const { isSimplifiedPageHeader } = usePageHeader();

  const retracked = useRetracked();
  const track = useTracker();
  const location = useLocation();

  const renderLogos = () => {
    const LogoComponentWrapperTag = logoWrapper as React.ElementType<$TSFixMe>;

    if (disableUserInteraction) {
      return (
        <LogoComponentWrapperTag className="m-a-0 body">
          <CourseraLogo hexColorCode={hexColorCode} />
        </LogoComponentWrapperTag>
      );
    } else {
      const homeURL = new URI('/');
      if (isAdminOrTeachPage) {
        homeURL.setPath('/admin');
      } else if (showEnterpriseLogo) {
        homeURL.setPath('/business/teams');
      } else if (program && program?.metadata?.slug) {
        const slug = program?.metadata?.slug;
        homeURL.setPath(`/programs/${slug}`);
      } else if (degree) {
        const { slug } = degree;
        homeURL.setPath(`/degrees/${slug}/home`);
      }

      forEach(logoQueryParams, (value, key) => {
        if (value === undefined) {
          return;
        }
        homeURL.addQueryParam(key, value);
      });

      let otherLogo;
      let label;

      if (enableCourseraLogoOnly) {
        otherLogo = null;
        label = null;
      } else if (showEnterpriseLogo) {
        label = _t('Coursera for Teams');

        if (affiliateElement) {
          otherLogo = (
            <span className="horizontal-box align-items-absolute-center  c-ph-logo-desktop-only">
              <div className="horizontal-box align-items-vertical-center header-logo">
                <div className="header-pipe" />
                {affiliateElement}
              </div>
            </span>
          );
        }
      } else if (isSimplifiedPageHeader && thirdPartyOrganization && !isLoggedInHome) {
        const programLogo = program?.metadata?.rectangularLogo || program?.metadata?.squareLogo;
        const programDisplayLogo =
          programLogoDisplay?.metadata?.rectangularLogo || programLogoDisplay?.metadata?.squareLogo;
        otherLogo = (
          <span className="horizontal-box align-items-absolute-center  c-ph-logo-desktop-only">
            <div className="horizontal-box align-items-vertical-center header-logo">
              <ThirdPartyOrganizationLogo thirdPartyOrganization={thirdPartyOrganization} logoWidth={160} />
              {!isOrgHome && (programDisplayLogo || programLogo) && (
                <>
                  <div className="header-pipe" />
                  <EnterpriseProgramLogo preferRectangle program={programLogoDisplay || program} logoWidth={160} />
                </>
              )}
            </div>
          </span>
        );
        label = _t('Coursera for #{name}', { name: thirdPartyOrganization.name });
      } else if (thirdPartyOrganization && !isLoggedInHome) {
        const programLogo = program?.metadata?.rectangularLogo || program?.metadata?.squareLogo;
        const programDisplayLogo =
          programLogoDisplay?.metadata?.rectangularLogo || programLogoDisplay?.metadata?.squareLogo;
        otherLogo = (
          <span className="horizontal-box align-items-absolute-center  c-ph-logo-desktop-only">
            <div className="horizontal-box align-items-vertical-center header-logo">
              <div className="header-pipe" />
              <ThirdPartyOrganizationLogo thirdPartyOrganization={thirdPartyOrganization} logoWidth={160} />
              {!isOrgHome && (programDisplayLogo || programLogo) && (
                <>
                  <div className="header-pipe" />
                  <EnterpriseProgramLogo preferRectangle program={programLogoDisplay || program} logoWidth={160} />
                </>
              )}
            </div>
          </span>
        );
        label = _t('Coursera for #{name}', { name: thirdPartyOrganization.name });
      } else if (course || degree) {
        if (course) {
          label = _t('#{name} home page | Coursera', { name: course.name });
        }

        if (degree) {
          label = _t('#{name} home page | Coursera', { name: degree.name });
        }
        otherLogo = <UniversityLogo course={course} degree={degree} />;
      }
      let logo = (
        <div css={styles.centerWrapper}>
          <CourseraLogo key="courseraLogo" ariaHidden />
          {otherLogo}
        </div>
      );

      if (isSimplifiedPageHeader) {
        logo = <div css={styles.centerWrapper}>{otherLogo ?? <CourseraLogo key="courseraLogo" ariaHidden />}</div>;
        if (/^\/purchase/.test(location.pathname)) {
          logo = (
            <div css={[styles.centerWrapper, !get().id && styles.padding]}>
              <CourseraLogo
                key="courseraLogo"
                hexColorCode={hexColorCode}
                imgSrc={TEAMS_LOGO_URL}
                ariaHidden
                className="team-logo"
              />
              {affiliateElement && otherLogo}
            </div>
          );
        }
      } else if (showEnterpriseLogo) {
        logo = (
          <div css={styles.centerWrapper}>
            <CourseraLogo
              key="courseraLogo"
              hexColorCode={hexColorCode}
              imgSrc={TEAMS_LOGO_URL}
              ariaHidden
              className="team-logo"
            />
            {affiliateElement && otherLogo}
          </div>
        );
      }

      return (
        <LogoComponentWrapperTag className="m-a-0 body">
          <TrackedLink2
            href={homeURL.toString()}
            className="c-logo horizontal-box align-items-vertical-center nostyle"
            trackingName="logo"
            aria-label={label || 'Coursera'}
          >
            {logo}
          </TrackedLink2>
        </LogoComponentWrapperTag>
      );
    }
  };

  const headerLogoWrapperClass = classNames(['rc-HeaderLogo', 'c-ph-logo', 'bt3-navbar-header']);
  const SwitcherSVGComponent = isOpened ? ChevronUpIcon : ChevronDownIcon;

  const [switcherButton, setSwitcherButton] = useState<HTMLButtonElement | null>(null);
  const isPopoverOpen = Boolean(switcherButton);

  const onOpenProgramMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setSwitcherButton(e.currentTarget);
    retracked({
      trackingData: {},
      trackingName: 'switcher_dropdown',
      action: 'click',
    });
    track('open_navigation_menu', {
      navigationType: 'homepage_switcher',
    });
  };

  const onHoverProgramMenu = () => {
    retracked({
      trackingData: {},
      trackingName: 'switcher_dropdown',
      action: 'hover',
    });
  };

  const onOldSwitcherClick = () => {
    track('open_navigation_menu', {
      navigationType: 'homepage_switcher',
    });

    handleProgramSwitcherToggle?.();
  };

  const onCloseProgramMenu = () => {
    setSwitcherButton(null);
  };

  return (
    <div css={styles.navbarHeaderContainer}>
      <div
        className={headerLogoWrapperClass}
        css={[styles.headerLogoWrapper, isSimplifiedPageHeader && styles.logoContainer]}
      >
        {displaySwitcher && isSimplifiedPageHeader && (
          <>
            <IconButton
              intent="menuSwitcher"
              onClick={onOpenProgramMenu}
              onMouseEnter={onHoverProgramMenu}
              data-testid="SwitcherButton"
              variant="ghost"
              css={styles.menuSwitcherIcon}
              tooltip={_t('Switch to')}
              aria-expanded={isPopoverOpen}
            />
            {isPopoverOpen && (
              <Popover
                anchorElement={switcherButton}
                open={isPopoverOpen}
                onClose={onCloseProgramMenu}
                drawerProps={{ autoFocus: false }}
              >
                <Popover.Body data-testid="SwitcherPanel">
                  <LoadableProgramSwitcher
                    currentProgram={program}
                    currentDegree={degree}
                    programs={programs}
                    degrees={degrees}
                  />
                </Popover.Body>
              </Popover>
            )}
          </>
        )}
        {renderLogos()}
        {displaySwitcher && !isSimplifiedPageHeader && (
          <button
            type="button"
            className="button-link horizontal-box align-items-absolute-center c-ph-logo-desktop-only"
            css={styles.switcherButton}
            onClick={onOldSwitcherClick}
            data-e2e="SwitcherButton"
            id="switcher-expander"
            aria-expanded={isOpened}
            aria-label={_t('Select Coursera Membership')}
          >
            <span className="align-items-absolute-center" css={styles.switcherWrapper}>
              <SwitcherSVGComponent
                size="small"
                className="switcher-svg"
                focusable={false}
                title={isOpened ? _t('Close Coursera membership menu') : _t('Open Coursera membership menu')}
              />
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default HeaderLogo;
