import React from 'react';

import useForkRef from '@material-ui/core/utils/useForkRef';

import clsx from 'clsx';

import { useId } from '@coursera/cds-common';
import { ErrorIcon, SuccessOutlineIcon } from '@coursera/cds-icons';

import type { BaseFormControlProps } from '@core/forms/FormControl';
import { FormControl } from '@core/forms/FormControl';
import type {
  RequireIndicatorProps,
  OptionalInputLabelProps,
} from '@core/forms/FormLabel';
import { FormLabel } from '@core/forms/FormLabel';
import { FormSupportText } from '@core/forms/FormSupportText';
import { FormValidationLabel } from '@core/forms/FormValidationLabel';
import type { InputProps } from '@core/forms/Input';
import { Input } from '@core/forms/Input';
import { InputAdornment } from '@core/forms/InputAdornment';
import { ariaLabelledByForMultipleLabels } from '@core/utils/a11y';

import CharacterLimit from './CharacterLimit';
import getTextFieldCss, { classes } from './getTextFieldCss';
import { useCharacterLimit } from './hooks';

export type Props = {
  /**
   * Defines default value for the field. Use when the component is not controlled.
   */
  defaultValue?: React.ReactText;

  /**
   * Defines value of the field. Required for a controlled component.
   */
  value?: React.ReactText;

  /**
   * Defines max characters and appropriately adjusts the width of the input.
   *
   * **Note:**
   *
   * - It does not adjust the width of the input when `fullWidth` is set;
   * - It works as fixed width.
   */
  maxLength?: number;

  /**
   * Defines minimum number of rows to display when `multiline` option is set to `true`.
   * @default 6
   */
  minRows?: InputProps['minRows'];

  /**
   * Defines HTML5 input attribute.
   * @default text
   */
  type?: 'text' | 'number' | 'email' | 'password' | 'tel' | 'url';

  /**
   * Defines ref passed to the `input` element.
   */
  inputRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement | null>;
  /**
   * Label to describe validation state of the TextField.
   */
  validationLabel?: string;

  /**
   * It prevents the user from changing the value of the field
   * (not from interacting with the field).
   * @default false
   */
  readOnly?: boolean;

  /**
   * Defines the character limit, different than `maxLength`.
   * It doesn't prevent users from entering more characters than the limit.
   */
  characterLimit?: number;

  /**
   * The callback is triggered once the character limit is reached.
   */
  onLimitReached?: (charactersTooMany: number) => void;
} & OptionalInputLabelProps &
  RequireIndicatorProps &
  BaseFormControlProps &
  Pick<
    InputProps,
    | 'onBlur'
    | 'onFocus'
    | 'onChange'
    | 'maxRows'
    | 'prefix'
    | 'suffix'
    | 'inputProps'
    | 'placeholder'
    | 'value'
    | 'name'
    | 'autoFocus'
    | 'autoComplete'
    | 'multiline'
  >;

const handleWheel = (event: Event) => {
  const isFocused = document.activeElement === event.target;
  if (isFocused && event.target instanceof HTMLInputElement) {
    event.target.blur();
  }
};

const handleBlur = (event: Event) => {
  event.target?.removeEventListener('wheel', handleWheel);
};

const handleFocus = (event: Event) => {
  event.target?.addEventListener('wheel', handleWheel, {
    passive: false,
  });
};

/**
 * TextField allows users to input data
 *
 * See [Props](__storybookUrl__/components-inputs-textfield--props)
 */
const TextField = React.forwardRef(function TextField(
  props: Props,
  ref: React.Ref<HTMLDivElement>
): React.ReactElement<Props> {
  const {
    id: idFromProps,
    supportText,
    label,
    renderLabel,
    suffix,
    prefix,
    className,
    disabled,
    fullWidth,
    optional,
    validationStatus,
    validationLabel,
    multiline = false,
    inputProps,
    maxLength,
    invert,
    readOnly,
    minRows = 6,
    inputRef: inputRefProp = null,
    onChange: onChangeProp,
    characterLimit,
    onLimitReached,
    type = 'text',
    necessityIndicator,
    'aria-label': ariaLabel = inputProps?.['aria-label'],
    'aria-labelledby': ariaLabelledBy = inputProps?.['aria-labelledby'],
    ...inputComponentProps
  } = props;

  const id = useId(idFromProps);
  let internalValidationStatus = validationStatus;

  const { charactersLeft, onChange, isLimitReached } = useCharacterLimit({
    onChange: onChangeProp,
    characterLimit,
    onLimitReached,
    value: inputComponentProps.value,
    defaultValue: inputComponentProps.defaultValue,
  });

  if (isLimitReached) {
    internalValidationStatus = 'error';
  }

  const supportTextId = supportText ? `${id}-support-text` : undefined;
  const characterLimitId = characterLimit ? `${id}-character-limit` : undefined;

  const validationLabelId =
    internalValidationStatus && validationLabel
      ? `${id}-validation-label`
      : undefined;

  const scrollInputRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(
    null
  );

  const inputRef = useForkRef(scrollInputRef, inputRefProp);

  // Determines Adornment alignment based on multiline prop
  const endAdornmentAlignment = multiline ? 'top' : undefined;

  let endAdornmentElement = suffix && (
    <InputAdornment hasSeparator={readOnly} position="end" variant="filled">
      {suffix}
    </InputAdornment>
  );

  const startAdornmentElement = prefix && (
    <InputAdornment hasSeparator={readOnly} position="start" variant="filled">
      {prefix}
    </InputAdornment>
  );

  if (internalValidationStatus === 'error') {
    // Compose suffix with validation icon
    endAdornmentElement = (
      <>
        <InputAdornment
          aria-hidden
          className={classes.validationIcon}
          data-testid="error-suffix"
          position="end"
          validationStatus="error"
          verticallyAligned={endAdornmentAlignment}
        >
          <ErrorIcon size="medium" />
        </InputAdornment>

        {endAdornmentElement}
      </>
    );
  }

  if (internalValidationStatus === 'success') {
    endAdornmentElement = (
      <InputAdornment
        aria-hidden
        className={classes.validationIcon}
        data-testid="success-suffix"
        position="end"
        validationStatus="success"
        verticallyAligned={endAdornmentAlignment}
      >
        <SuccessOutlineIcon size="medium" />
      </InputAdornment>
    );
  }

  /**
   * Handle number field wheel event
   * TODO: Cleanup https://coursera.atlassian.net/browse/CDS-710
   */
  React.useEffect(
    function preventWheel() {
      const node = scrollInputRef.current;

      if (type === 'number') {
        node?.addEventListener('focus', handleFocus);
        node?.addEventListener('blur', handleBlur);
      }

      return () => {
        if (type === 'number') {
          node?.removeEventListener('wheel', handleWheel);

          node?.removeEventListener('focus', handleFocus);
          node?.removeEventListener('blur', handleBlur);
        }
      };
    },
    [type]
  );

  return (
    <FormControl
      ref={ref}
      className={clsx(
        {
          [classes.readOnly]: readOnly,
          [classes.valid]: internalValidationStatus === 'success',
          [classes.invalid]: internalValidationStatus === 'error',
        },
        className
      )}
      css={getTextFieldCss}
      disabled={disabled}
      fullWidth={fullWidth}
      id={id}
      invert={invert}
      optional={optional}
      supportText={supportText}
      validationStatus={internalValidationStatus}
    >
      {(label || renderLabel) && (
        <FormLabel
          necessityIndicator={
            !necessityIndicator && optional ? 'text' : necessityIndicator
          }
          renderLabel={renderLabel}
        >
          {label}
        </FormLabel>
      )}

      {supportText && (
        <FormSupportText className={classes.formSupportText}>
          {supportText}
        </FormSupportText>
      )}

      {internalValidationStatus && validationLabel && (
        <FormValidationLabel
          hideIcon
          className={classes.formValidationLabel}
          label={validationLabel}
        />
      )}

      <Input
        {...inputComponentProps}
        classes={{
          root: classes.root,
          focused: classes.focused,
        }}
        fullWidth={fullWidth}
        inputProps={{
          'aria-label': ariaLabel || label,
          'aria-labelledby': ariaLabelledBy,
          'aria-describedby': ariaLabelledByForMultipleLabels(
            validationLabelId,
            characterLimitId,
            supportTextId
          ),
          maxLength,
          ...inputProps,
        }}
        inputRef={inputRef}
        minRows={minRows}
        multiline={multiline}
        prefix={startAdornmentElement}
        readOnly={readOnly}
        suffix={endAdornmentElement}
        type={type}
        onChange={onChange}
      />

      {characterLimit !== undefined && charactersLeft !== undefined ? (
        <CharacterLimit
          characterLimit={characterLimit}
          charactersLeft={charactersLeft}
          className={classes.characterLimit}
          id={characterLimitId}
          isLimitReached={isLimitReached}
        />
      ) : null}
    </FormControl>
  );
});

export default TextField;
