import { useCallback, useEffect, useRef, useState } from 'react';

import * as Sentry from '@sentry/react';

import { getLanguageCode } from 'js/lib/language';
import thirdParty from 'js/lib/thirdParty';

const SCRIPT_URL = 'https://coursera-api.arkoselabs.com/v2/api.js';
const STATUS_URL = 'https://status.arkoselabs.com/api/v2/status.json';
const MAXIMUM_RETRIES = 3;
const MAXIMUM_TIMEOUT_DURATION = 5000;

export const USER_CLOSED_CHALLENGE = 'Challenge closed by user';
export const ARKOSE_CLIENT_SIDE_ERROR = 'arkoseClientSideError';

type Props = {
  publicKey: string;
  maxRetries?: number;
  timeoutDuration?: number;
  shouldSkipHook?: boolean;
  onHideCallback?: () => void;
};

type Enforcement = {
  setConfig: (config: {
    publicKey: string;
    language: string;
    onReady: () => void;
    onHide: () => void;
    onCompleted: (response: { token: string }) => void;
    onError: (error?: { error: string }) => void;
  }) => void;
  run: () => void;
  reset: () => void;
};

declare global {
  interface Window {
    setupEnforcement?: (enforcement: Enforcement) => void;
  }
}

export type ArkoseBotManager = {
  reset: () => void | undefined;
  getToken: () => Promise<string>;
};

function getLanguage() {
  const code = getLanguageCode();
  return code === 'zh-mo' ? 'zh' : code;
}

/**
 * Custom React hook that manages the integration with Arkose Labs for bot detection.
 * This hook handles loading the Arkose Labs API script, setting up enforcement,
 * retry logic, timeout management, and API health checking.
 *
 * @param {string} publicKey - The Arkose Labs public key required for API integration.
 *                             Different flows may use different public keys.
 * @param {number} [maxRetries=MAXIMUM_RETRIES] - The maximum number of retry attempts if loading the script fails.
 * @param {number} [timeoutDuration=MAXIMUM_TIMEOUT_DURATION] - The timeout duration in milliseconds before the script retries.
 * @param {boolean} [shouldSkipHook=false] - The boolean to control whether the hook's logic should be executed.
 * @param {function} onHideCallback Callback invoked when the challenge is closed via the ESC key, the X close button, or after the onCompleted callback.
 *
 * @returns {object} - Returns an object with the following methods:
 *   - `reset`: A method to reset the Arkose Labs enforcement if necessary.
 *   - `getToken`: A method to initiate the enforcement process and return a token when no challenge is needed OR the challenge is completed.
 */
const useArkoseBotManager = ({
  publicKey,
  maxRetries = MAXIMUM_RETRIES,
  timeoutDuration = MAXIMUM_TIMEOUT_DURATION,
  shouldSkipHook = false,
  onHideCallback,
}: Props): ArkoseBotManager => {
  const enforcementRef = useRef<Enforcement | null>(null);
  const tokenPromiseRef = useRef<Promise<string>>(new Promise(() => {}));
  const tokenResolverRef = useRef<(token: string) => void>(() => {});
  const tokenRejecterRef = useRef<(error: Error) => void>(() => {});
  const isCompleteTriggeredRef = useRef(false);

  const [scriptRetryCount, setScriptRetryCount] = useState(0);
  const [enforcementRetryCount, setEnforcementRetryCount] = useState(0);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isReadyTriggered, setIsReadyTriggered] = useState(false);

  // Helper function to create a new token promise
  const createNewTokenPromise = () => {
    tokenPromiseRef.current = new Promise<string>((resolve, reject) => {
      tokenResolverRef.current = resolve;
      tokenRejecterRef.current = reject;
    });
  };

  const checkArkoseAPIHealthStatus = async () => {
    try {
      const response = await fetch(STATUS_URL);
      const data = await response.json();
      return data.status.indicator === 'none'; // status "none" indicates Arkose systems are healthy
    } catch (error) {
      return false;
    }
  };

  const handleFailure = useCallback((error: string) => {
    Sentry.captureException('useArkoseBotManager error', { extra: { error } });
    tokenRejecterRef.current(new Error(error));
  }, []);

  const onCompleted = useCallback((token) => {
    tokenResolverRef.current(token);
    isCompleteTriggeredRef.current = true;
  }, []);

  const onHide = useCallback(() => {
    if (onHideCallback) onHideCallback();

    // Handle the scenario where the user closes the challenge via ESC or the close button.
    // This excludes cases where the onHide callback is triggered after the challenge is successfully completed.
    if (!isCompleteTriggeredRef.current) {
      tokenRejecterRef.current(new Error(USER_CLOSED_CHALLENGE));
      createNewTokenPromise();
    }
  }, [isCompleteTriggeredRef, onHideCallback]);

  const onReady = () => {
    setScriptRetryCount(0); // Reset retry count on the Arkose API ready
    setIsReadyTriggered(true);
  };

  const onError = useCallback(
    async (error?: string) => {
      const arkoseStatus = await checkArkoseAPIHealthStatus();
      if (arkoseStatus && enforcementRetryCount < maxRetries) {
        enforcementRef.current?.reset();
        setEnforcementRetryCount((prev) => prev + 1);
        // To ensure the enforcement has been successfully reset, we need to set a timeout here
        setTimeout(() => {
          enforcementRef.current?.run();
        }, 500);
        return;
      }
      // The Arkose API is unhealthy or retry count has exceeded
      if (error) {
        handleFailure(error);
      }
    },
    [enforcementRetryCount, handleFailure, maxRetries]
  );

  const handleScriptSuccess = () => {
    setIsScriptLoaded(true);
  };

  const loadScript = useCallback(async () => {
    try {
      await thirdParty.loadScript({
        url: SCRIPT_URL,
        attributes: {
          defer: true,
          'data-callback': 'setupEnforcement',
        },
        retries: maxRetries,
      });
      handleScriptSuccess();
    } catch (error) {
      handleFailure(error);
    }
  }, [handleFailure, maxRetries]);

  const retryLoadingScript: () => void = useCallback(() => {
    if (scriptRetryCount < maxRetries) {
      setScriptRetryCount(scriptRetryCount + 1);
      thirdParty.removeScript(SCRIPT_URL);

      loadScript();
    } else {
      handleFailure(`Failed to load the Arkose API Script after ${maxRetries} attempts`);
    }
  }, [handleFailure, loadScript, maxRetries, scriptRetryCount]);

  // Function to setup enforcement after the Arkose script loads
  const setupEnforcement = useCallback(
    (enforcement: Enforcement) => {
      enforcementRef.current = enforcement;
      enforcementRef?.current?.setConfig({
        publicKey,
        language: getLanguage(),
        onReady: () => onReady(),
        onHide: () => onHide(),
        onCompleted: (response) => onCompleted(response.token),
        onError: (response) => onError(response?.error),
      });
    },
    [onCompleted, onError, onHide, publicKey]
  );

  useEffect(() => {
    if (shouldSkipHook) return;

    // Set window setupEnforcement function
    window.setupEnforcement = setupEnforcement;

    // Try loading the script
    loadScript();
  }, [loadScript, setupEnforcement, shouldSkipHook]);

  useEffect(() => {
    if (shouldSkipHook) return () => {};

    // Timeout check for onReady callback,
    // if onReady is not triggered when timeout, retry loading the Arkose API Script
    const timer = setTimeout(() => {
      if (!isReadyTriggered && isScriptLoaded) {
        retryLoadingScript();
      }
    }, timeoutDuration);

    createNewTokenPromise();

    return () => {
      if (window.setupEnforcement) {
        delete window.setupEnforcement;
      }
      clearTimeout(timer);
      thirdParty.removeScript(SCRIPT_URL);
    };
  }, [
    isScriptLoaded,
    loadScript,
    onCompleted,
    publicKey,
    isReadyTriggered,
    retryLoadingScript,
    setupEnforcement,
    timeoutDuration,
    shouldSkipHook,
  ]);

  if (shouldSkipHook) {
    return {
      reset: () => {},
      getToken: () => Promise.reject(new Error('Hook skipped')),
    };
  }

  return {
    // Expose a method to reset the enforcement
    reset: () => {
      createNewTokenPromise();
      enforcementRef.current?.reset();
    },
    getToken: () => {
      enforcementRef.current?.run();
      return tokenPromiseRef.current;
    },
  };
};

export default useArkoseBotManager;
