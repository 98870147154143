/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { createRef, useEffect } from 'react';

import classNames from 'classnames';
import { func } from 'prop-types';

import type { ActionContext } from 'js/lib/ActionContext';

import type { Theme } from '@coursera/cds-core';

import InternationalPhoneNumberInput from 'bundles/account-profile/components/i18n-phone-number-input/InternationalPhoneNumberInput';
import type { CustomStyles, ValidPhone } from 'bundles/account-profile/components/i18n-phone-number-input/types';
import CDSDropdownElement from 'bundles/survey-form/components/CDSDropdownElement';
import CDSTextInputElement from 'bundles/survey-form/components/CDSTextInputElement';
import CheckboxesElement from 'bundles/survey-form/components/CheckboxesElement';
import { QuestionTypes } from 'bundles/survey-form/constants/SurveyFormConstants';
import type { QuestionType } from 'bundles/survey-form/constants/SurveyFormConstants';
import { getQuestionType, getResponseTypeFromQuestionType } from 'bundles/survey-form/utils/SurveyFormUtils';
import ProgramQualificationActions from 'bundles/university-program-qualification/actions/ProgramQualificationActions';

import _t from 'i18n!nls/university-program-qualification';

import 'css!./__styles__/SurveyQuestion';

type SurveyQuestionProps = {
  questionData: $TSFixMe;
  validationError?: string;
  showAsterix?: boolean;
  focusOnInvalidQuestion?: boolean;
  setRegion?: (region: string) => void;
};

type LegacyContext = {
  executeAction: ActionContext['executeAction'];
};

const { SHORT_ANSWER, DROPDOWN, NUMERIC, CHECKBOX } = QuestionTypes;
const { updateResponse } = ProgramQualificationActions;

// Define custom styles for the formatted phone input
export const customFormattedPhoneStyles: CustomStyles = {
  phoneNumberLabel: (theme: Theme) => css`
    font-weight: 600;
    letter-spacing: -0.1px;
    margin-bottom: var(--cds-spacing-100);
  `,
  phoneNumberTextField: (theme: Theme) => css`
    margin-top: calc(${theme.spacing(16)} * -1);
  `,
  optionalText: css`
    font-style: italic;
    color: #636363;
    margin-left: 10px;
  `,
  phoneNumberInputContainer: css`
    flex-direction: column;
  `,
};

export const formChangeFormatter = (phone?: ValidPhone) => {
  const phoneNumberForEoiForm = { text: phone ? phone.phoneNumber.toString() : '' };
  return phoneNumberForEoiForm;
};

const SurveyQuestion: React.FC<SurveyQuestionProps> = (
  { questionData, validationError, showAsterix = true, setRegion, focusOnInvalidQuestion = false },
  context: LegacyContext
) => {
  const inputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (focusOnInvalidQuestion) {
      inputRef.current?.focus();
    }
    // We only care if this prop updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusOnInvalidQuestion]);

  // Handle input changes for the questions and update the response
  const handleQuestionInputChange = (questionId: string, questionType: QuestionType) => {
    return (response: $TSFixMe) => {
      const responseType = getResponseTypeFromQuestionType(questionType);
      const responseToUpdate = {
        [questionId]: {
          [responseType]: response,
        },
      };
      context.executeAction(updateResponse, responseToUpdate);
    };
  };

  // Render question label with or without validation errors
  const renderQuestionLabel = (prompt: $TSFixMe, isRequired: boolean) => {
    const labelClassName = classNames('question-label', {
      'question-error': validationError,
    });
    return (
      <div className={labelClassName}>
        <p className="question-prompt label-text" aria-label={`${prompt} ${isRequired ? _t('Required field') : ''}`}>
          {prompt} {isRequired && showAsterix && '*'}
        </p>
        {validationError && (
          <p className="question-error-message caption-text" role="alert">
            {validationError}
          </p>
        )}
      </div>
    );
  };

  const questionType = getQuestionType(questionData);
  const questionId = questionData.id;
  const defaultValue = questionData.defaultValue;
  const { displayHalfLine } = questionData;
  const questionClassName = classNames('rc-SurveyQuestion', {
    'half-line-question': displayHalfLine,
  });
  const numericType = questionType === NUMERIC;
  const { prompt, placeholder } = questionData.content[questionType];
  const { isRequired } = questionData;

  switch (questionType) {
    case NUMERIC:
    case SHORT_ANSWER: {
      const labelErrorString = _t('#{textFieldLabel}: #{validationError}', {
        textFieldLabel: prompt,
        validationError,
      });
      const ariaLabel = validationError ? labelErrorString : prompt;
      let renderedElement;

      if (questionId === 'phoneNumber') {
        renderedElement = (
          <InternationalPhoneNumberInput
            profile={{ phone: defaultValue }}
            customStyles={customFormattedPhoneStyles}
            isEmptyValid={false}
            submissionError={!!validationError}
            isOptional={!isRequired}
            formChangeFormatter={formChangeFormatter}
            handleFormChange={handleQuestionInputChange(questionId, questionType)}
            setRegion={setRegion}
            ref={inputRef}
          />
        );
      } else {
        renderedElement = (
          <CDSTextInputElement
            validationError={validationError}
            prompt={prompt}
            onChange={handleQuestionInputChange(questionId, questionType)}
            placeholder={placeholder ?? _t('Enter #{fieldName}', { fieldName: prompt })}
            defaultValue={defaultValue}
            numericType={numericType}
            id={questionId}
            ariaLabel={`${ariaLabel} ${isRequired ? _t('Required field') : ''}`}
            isRequired={isRequired}
            ref={inputRef}
          />
        );
      }

      return <div className={questionClassName}>{renderedElement}</div>;
    }
    case DROPDOWN: {
      const { options } = questionData.content[questionType];
      return (
        <div className={questionClassName}>
          <CDSDropdownElement
            isRequired={isRequired}
            validationError={validationError}
            defaultValue={defaultValue}
            prompt={prompt}
            placeholder={placeholder ?? _t('Choose Option')}
            onChange={handleQuestionInputChange(questionId, questionType)}
            options={options}
            ref={inputRef}
          />
        </div>
      );
    }
    case CHECKBOX: {
      const { helpText, includeOther, options, placeholderForOther } = questionData.content[questionType];
      return (
        <div className={questionClassName}>
          {renderQuestionLabel(prompt, isRequired)}
          <CheckboxesElement
            onChange={handleQuestionInputChange(questionId, questionType)}
            helpText={helpText}
            options={options}
            enableErrorState={!!validationError}
            includeOther={includeOther}
            placeholderForOther={placeholderForOther}
            inputRef={inputRef}
          />
        </div>
      );
    }

    default: {
      return <div />;
    }
  }
};

SurveyQuestion.contextTypes = {
  executeAction: func.isRequired,
};

export default SurveyQuestion;
