/* @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { Theme } from '@coursera/cds-core';
import { Grid, Typography, Typography2, useTheme } from '@coursera/cds-core';
import type { CSSProperties } from '@coursera/coursera-ui';

import type { Maybe } from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import type { InstructorProfileFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';

type Props = {
  instructorMosaicHeader?: Maybe<string>;
  instructors?: Array<Maybe<InstructorProfileFragment>>;
};

const styles: Record<string, CSSProperties> = {
  instructorsWrapper: (theme: Theme) => ({
    paddingTop: theme.spacing(64),
    '.instructorsProfilesContainer': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      [`@media (max-width: ${theme.breakpoints.values.md}px)`]: { justifyContent: 'start' },
    },

    '.instructorProfile': {
      display: 'flex',
      paddingBottom: theme.spacing(64),
      [`@media (max-width: ${theme.breakpoints.values.md}px)`]: { paddingBottom: theme.spacing(32), maxWidth: '100%' },
    },
  }),
  titleContainer: (theme: Theme) => ({
    marginBottom: theme.spacing(64),
    textAlign: 'center',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: { marginBottom: theme.spacing(32) },
  }),
  profilePicture: (theme: Theme, profileImage: $TSFixMe) => ({
    height: '108px',
    width: '108px',
    marginRight: theme.spacing(24),
    backgroundImage: `url(${profileImage})`,
    backgroundSize: '100% auto',
    backgroundRepeat: 'no-repeat',
    borderRadius: '50%',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: { height: '84px', width: '84px' },
  }),
};

const UnifiedInstructorsList: React.FunctionComponent<Props> = ({ instructorMosaicHeader, instructors }) => {
  const theme = useTheme();
  if (!instructors || !instructors.length) {
    return null;
  }

  return (
    <div css={styles.instructorsWrapper(theme)} data-test="rc-UnifiedInstructorsList">
      <Grid container direction="column">
        <Typography component="h2" variant="h1" css={styles.titleContainer(theme)}>
          {instructorMosaicHeader}
        </Typography>
        <Grid container className="instructorsProfilesContainer">
          {instructors.map((instructor) => {
            if (!instructor) {
              return null;
            }
            const { name, position, partner, program } = instructor;
            const profileImage = instructor?.profilePicture?.url || instructor?.profilePicture;
            const instructorDescription = [position, partner, program]
              .filter((el) => el !== undefined && el !== null)
              .join(', ');

            return (
              <Grid
                item
                key={name}
                className="instructorProfile"
                sm={12}
                md={4}
                lg={4}
                xl={4}
                data-test="instructorProfile"
              >
                <div>
                  <div css={styles.profilePicture(theme, profileImage)} />
                </div>
                <div>
                  <Typography2 variant="subtitleMedium" component="p">
                    {name}
                  </Typography2>
                  <Typography2 component="p" variant="bodySecondary">
                    {instructorDescription}
                  </Typography2>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};

export default UnifiedInstructorsList;
