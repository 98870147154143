import { css } from '@emotion/react';
import type { SerializedStyles, Theme } from '@emotion/react';

import OfferExplanationTableStyle from 'bundles/browse/components/OfferExplanationTableStyle';

/**
 * These shared config items eliminate the need for non-performant calls to the theme.
 * This should help reduce the time it takes to calculate Emotion styles.
 * See this blog post for more: https://itnext.io/how-to-increase-css-in-js-performance-by-175x-f30ddeac6bce
 */

const config = {
  sizes: {
    down: {
      xs: 599,
      sm: 1060,
      md: 1439,
    },
    up: {
      xs: 0,
      sm: 600,
      md: 1061,
    },
  },
} as const;

export interface SearchTheme extends Theme {
  searchPageContainer: SerializedStyles;
  entityPageContainer: SerializedStyles;
  seoEntityNested: SerializedStyles;
  entityPageBody: SerializedStyles;
  productInfo: SerializedStyles;
  productTypePill: SerializedStyles;
  shortFormContentBreadcrumb: SerializedStyles;
  shortFormContentProductInfo: SerializedStyles;
  mainContent: SerializedStyles;
}

export function isSearchTheme(theme: Theme): theme is SearchTheme {
  return !!(theme as SearchTheme).searchPageContainer;
}

export const searchTheme = (theme: Theme): SearchTheme => ({
  ...theme,
  searchPageContainer: css`
    background-color: white;
    overflow-x: hidden; /* mobile-friendly */
    overflow-y: hidden;

    .bt3-container.body-container {
      max-width: 100vw;
      margin: 0;
      padding: 0;
      width: 100%;

      @media screen and (max-width: ${config.sizes.down.sm}px) {
        padding-right: 0;
        padding-left: 0;
      }
    }

    @media screen and (max-width: ${config.sizes.down.sm}px) {
      .mobile-search-page-bar {
        padding: 12px 14px;

        .rc-SearchBar {
          width: 100%;
          margin: 0;

          .rc-AutoComplete {
            width: 100%;
          }
        }
      }
    }

    hr {
      width: 100%;
    }

    .filter-menu-and-number-of-results {
      & > div:first-of-type {
        width: 100%;
      }
    }

    .rc-NumberOfResultsSection {
      padding: 0 180px;
      position: relative;
      width: auto;

      @media (max-width: 1450px) {
        margin-left: auto;
        margin-right: auto;
        max-width: 1110px;
        padding: 0 20px;
      }

      @media (max-width: 1023px) {
        margin: 0;
      }
    }

    .rc-DesktopSearchFilters {
      margin-left: 0;
      padding: 0 180px;

      @media screen and (max-width: ${config.sizes.down.md}px) {
        padding: 0 20px;
        position: relative;
        overflow-x: visible;
        margin-left: auto;
        margin-right: auto;
        max-width: 1110px;
      }
    }

    ${OfferExplanationTableStyle}

    .course-list-container {
      margin: 20px 0;
    }

    .c2b-banner-wrapper {
      border-top: 1px solid #e1e1e1;
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 40px;
      margin: 0;
    }

    .mobile-private-session-dates {
      font-size: 12px;
      font-weight: bold;
      margin-top: 12px;
      color: var(--cds-color-grey-975);
    }
  `,
  mainContent: css`
    background-color: var(--cds-color-grey-25);
    padding: 36px 0;
  `,
  entityPageContainer: css`
    .internal-linking-wrapper {
      display: block;
      margin-top: 60px;
      margin-bottom: 61px;
      padding: 0 16px;

      .skills-to-learn-section {
        max-width: 1116px;
        margin-left: auto;
        margin-right: auto;
      }

      .faq-and-topics-container {
        max-width: 1114px;
        margin-left: auto;
        margin-right: auto;

        @media screen and (max-width: ${config.sizes.down.md}px) {
          margin: 45px auto;
        }
      }
    }
  `,
  seoEntityNested: css`
    .rc-EntityPageBreadcrumbs {
      list-style: none;
      padding: 0;
      display: flex;
      align-items: 'center';
      margin-bottom: 24px;
      margin-top: 0;

      @media screen and (max-width: ${config.sizes.down.md}px) {
        margin-bottom: 16px;
      }

      .breadcrumb-item {
        display: flex;
        align-items: center;
      }

      .breadcrumb-title {
        color: var(--cds-color-grey-975) !important;
        margin-right: 16px;
      }

      .breadcrumb-divider {
        color: var(--cds-color-grey-975) !important;
        margin-right: 16px;
        display: inline-block;
      }
    }

    .rc-DomainTopSkills {
      margin-bottom: 48px;
      max-width: 100%;

      .domain-skills-header {
        font-family: var(--cds-font-family-source-sans-pro);
        font-weight: 600;
        margin-bottom: 16px;
      }

      .shouldHide {
        max-width: fit-content;
        display: none;
      }

      .showAllSkills {
        display: none;
      }

      .rc-DomainTopSkillPill {
        font-family: var(--cds-font-family-source-sans-pro);
      }

      .showMoreTrigger {
        font-weight: 600;
        padding-left: 0;
        padding-top: 16px;

        &:hover {
          background-color: var(--cds-color-white-0);
        }
      }
    }
  `,
  entityPageBody: css`
    margin: 0 auto;
    max-width: 1116px;

    @media screen and (max-width: ${config.sizes.down.md}px) {
      max-width: 90%;
    }
  `,
  productInfo: css`
    padding: 0;

    .rating-enroll-wrapper {
      display: flex;
      align-items: center;
      padding-top: 12px;

      .rc-Ratings {
        height: 100%;
        align-items: center;
        margin: 0;
      }

      .ratings-text,
      .enrollment-number,
      .ratings-count {
        font-family: var(--cds-font-family-source-sans-pro) !important;
        color: var(--cds-color-grey-975);
      }

      .ratings-text {
        font-family: var(--cds-font-family-source-sans-pro);
        padding-right: 0;
      }

      .ratings-count {
        font-family: var(--cds-font-family-source-sans-pro);
        white-space: pre;

        &::before {
          content: ' ';
          display: inline-block;
        }
      }

      .enrollment {
        span {
          font-family: var(--cds-font-family-source-sans-pro);
          color: var(--cds-color-grey-975);
        }

        .enrollment-number {
          font-family: var(--cds-font-family-source-sans-pro);
          padding-right: 0;
        }
      }

      .info-item {
        display: flex;
        height: 20px;
        align-items: center;
        border-left: 1px solid var(--cds-color-grey-975);
        padding-left: 8px;
        padding-right: 8px;

        &:first-of-type {
          border-left: none;
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }
    }

    .tagline {
      color: var(--cds-color-grey-600);
      margin: 0;
    }

    .product-difficulty {
      padding-top: 10px;
    }
  `,
  productTypePill: css`
    border-radius: 2px;
    font-size: 14px !important;
    font-weight: normal;
    width: fit-content;
    text-decoration: none;
    color: var(--cds-color-grey-975);
    display: inline-block;
    text-align: center;
    background-color: var(--cds-color-grey-50);

    .withoutGradient {
      padding: 2px 11px 3px;
      font-weight: 'normal';
    }

    .pillContainer {
      justify-content: center;

      @media screen and (max-width: ${config.sizes.down.xs}px) {
        justify-content: flex-start;
      }
    }
  `,
  shortFormContentBreadcrumb: css`
    margin-top: -4px;
    margin-bottom: 8px;
    margin-left: 2px;

    .product-name-link {
      margin-left: 4px;
      margin-right: 4px;
      font-size: 13px !important;
    }

    .product-name {
      font-size: 13px !important;
      color: #525252;
    }

    .check-icon {
      margin-top: 0;
      margin-bottom: -3px;
    }

    .sfc-breadcrumb-lesson-container {
      white-space: nowrap;
    }
  `,
  shortFormContentProductInfo: css`
    .short-form-content-info {
      margin-top: 6px !important;

      .duration-icon {
        margin-top: 2px !important;
        color: black;
      }

      .bullet-point {
        color: black !important;
      }
    }
  `,
});
