/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import imgixLib from 'js/lib/imgix';

import { Button, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import { SvgPlayFilled } from '@coursera/coursera-ui/svg';
import { useTracker } from '@coursera/event-pulse/react';

import TrackedDiv from 'bundles/page/components/TrackedDiv';
import type { PremiumHubImageAssetFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';
import Overlay from 'bundles/xddp/components/shared/Overlay';

import _t from 'i18n!nls/premium-hub';

type Props = {
  videoEmbedUrl?: string | null;
  placeholderImage: PremiumHubImageAssetFragment;
  index: number;
  authorName?: string | null;
  authorTitle?: string | null;
  text?: string | null;
};

const styles = {
  videoPlaceholderContainer: (imageUrl: string, theme: Theme) => css`
    cursor: pointer;
    ${theme.breakpoints.up('sm')} {
      height: 434px;
    }
    ${theme.breakpoints.down('sm')} {
      height: 200px;
    }

    position: relative;
    z-index: 3;
    width: 100%;
    border-radius: 8px;
    background-image: url(${imageUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  `,
  playIcon: (theme: Theme) => css`
    position: absolute;
    z-index: 3;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    border: 3px solid var(--cds-color-white-0);
    border-radius: 36px;
    width: 66px;
    height: 66px;
    padding: 14px 14px;
    margin: 0 auto;

    :hover,
    :active,
    :focus {
      background-color: initial;
    }
  `,
  videoOverlay: (theme: Theme) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: ${theme.palette.black['500']};
    opacity: 0.2;
    border-radius: 6px;
  `,

  blackOverlay: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: var(--cds-color-grey-975);
    opacity: 0.5;
  `,

  // Default dimensions to render Youtube video properly
  videoPlayerContainer: css`
    @media (min-width: 288px) {
      height: 128px;
    }

    @media (min-width: 576px) {
      height: 324px;
    }

    @media (min-width: 792px) {
      height: 445px;
    }

    @media (min-width: 1008px) {
      height: 567px;
    }

    @media (min-width: 1152px) {
      height: 648px;
    }
  `,
};

const TestimonialVideo: React.FC<Props> = ({
  videoEmbedUrl,
  placeholderImage,
  index,
  authorName,
  authorTitle,
  text,
}) => {
  const [showVideoPlayback, setVideoPlayback] = useState(false);
  const theme = useTheme();
  const trackV3 = useTracker();

  if (!videoEmbedUrl || !placeholderImage) {
    return null;
  }

  const compressedImageUrl = imgixLib.processImage(placeholderImage.url, {
    auto: 'format,compress',
    width: 550,
    quality: 60,
  });

  return (
    <TrackedDiv
      trackingName="testimonial"
      data={{ indexPosition: index + 1, testimonialName: authorName ?? '', testimonialTitle: authorTitle ?? '' }}
      withVisibilityTracking
      onClick={() => {
        setVideoPlayback(!showVideoPlayback);
        trackV3('click_testimonial_card', {
          testimonialCard: {
            author: authorName ?? '',
            text: text ?? '',
            index: index ?? -1,
          },
          testimonialAction: 'play_video',
        });
      }}
      role="button"
      aria-label={placeholderImage.description}
    >
      <div css={styles.videoPlaceholderContainer(compressedImageUrl, theme)}>
        <Button variant="ghost" css={styles.playIcon(theme)} tabIndex={-1}>
          <SvgPlayFilled size={36} color="white" />
        </Button>
        <div css={styles.blackOverlay} />
      </div>

      {showVideoPlayback && (
        <div data-testid="open-video-overlay">
          <Overlay handleClose={() => setVideoPlayback(!showVideoPlayback)} isOpen={showVideoPlayback}>
            <div css={styles.videoPlayerContainer}>
              <iframe
                title={_t('Youtube Embedded Video')}
                width="100%"
                height="100%"
                src={videoEmbedUrl}
                frameBorder="0"
                allowFullScreen
              />
            </div>
          </Overlay>
        </div>
      )}
    </TrackedDiv>
  );
};

export default TestimonialVideo;
