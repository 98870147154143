/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useState } from 'react';

import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';
import { useRetracked } from 'js/lib/retracked';

import {
  Button,
  Grid,
  MultiSelect,
  SelectOption,
  SilentSelectField,
  Typography,
  Typography2,
  breakpoints,
} from '@coursera/cds-core';
import { FilterIcon } from '@coursera/cds-icons';
import { useTracker } from '@coursera/event-pulse/react';

import TrackedDiv from 'bundles/page/components/TrackedDiv';
import DegreeEoiButton from 'bundles/premium-hub/components/degrees/DegreeEoiButton';
import DegreeFiltersMobile from 'bundles/premium-hub/components/degrees/DegreeFiltersMobile';
import type { DegreeFilterState } from 'bundles/premium-hub/components/degrees/useMultiSelectDegreeFilterStateManager';
import type { PremiumHubPageType } from 'bundles/premium-hub/constants';
import { HubPages, REGIONAL_CATEGORIES } from 'bundles/premium-hub/constants';
import type { Domain, PremiumProductWithMetadata } from 'bundles/premium-hub/types/index';
import { getNameFromProductVariant } from 'bundles/premium-hub/utils/index';

import _t from 'i18n!nls/premium-hub';

type Props = {
  degreeFilterState: DegreeFilterState;
  slug: string;
  productId: string;
  categoryName?: string;
  surveyProductType: string;
  currentPageType?: PremiumHubPageType;
  domains: Domain[];
  degrees: Array<PremiumProductWithMetadata>;
};

const styles = {
  filterGroup: css`
    ${breakpoints.down('xs')} {
      display: none;
    }
  `,
  allFilter: css`
    ${breakpoints.up('sm')} {
      display: none;
    }
  `,
  resetFilter: css`
    ${breakpoints.down('xs')} {
      display: none;
    }
  `,
};

const DegreeFilters: React.FunctionComponent<Props> = ({
  degreeFilterState,
  slug,
  productId,
  categoryName,
  surveyProductType,
  currentPageType,
  domains,
  degrees,
}) => {
  const trackComponent = useRetracked();
  const trackV3 = useTracker();
  const [filterStatusMessage, setFilterStatusMessage] = useState<string>('');

  const { Option } = MultiSelect;

  const {
    submittedDomain,
    submittedProductVariant,
    submittedApplicationOpen,
    setSubmittedProductVariant,
    setSubmittedDomain,
    setSubmittedApplicationOpen,
    availableProductVariantFilters = [],
    availableDomainFilters = [],
    availableApplicationOpenFilters = [],
    selectedProductVariantValue = [],
    selectedDomainValue = [],
    selectedProductVariant = '',
    selectedApplicationOpenValue,
    open = false,
    setOpen,
    clearAll,
  } = degreeFilterState;

  const showApplicationOpenFilter = false;
  const handleClose = () => setOpen?.(false);
  const filterNumber =
    selectedProductVariantValue.length + selectedDomainValue.length + (selectedApplicationOpenValue ? 1 : 0);
  const filterText = filterNumber ? `(${filterNumber})` : '';

  const isLevelHub = currentPageType === HubPages.ProductVariantHubPage;
  const isCategoryHub = currentPageType === HubPages.DegreesCategoryHubPage;
  const isRegional = categoryName && REGIONAL_CATEGORIES.includes(categoryName);

  const hasFiltersSelected = submittedDomain || submittedProductVariant || submittedApplicationOpen;
  const filterCount = selectedProductVariantValue.length + selectedDomainValue.length;
  const totalResults = degrees?.length || 0;
  const productCardEpicMessage = totalResults === 1 ? _t(`{totalResults} result`) : _t(`{totalResults} results`);
  const domainName = domains?.find((domainData) => domainData.id === submittedDomain)?.name;
  const STATUS_ANNOUNCEMENT_DELAY = 500;

  useEffect(() => {
    /* 
      The timeout resolves an issue in Jaws where the filter status announcement was interrupted
      by the multiselect component refocusing after making a selection.
    */
    setFilterStatusMessage('');
    setTimeout(() => {
      const resultsMessage = totalResults === 1 ? _t('1 result') : _t('#{totalResults} results', { totalResults });
      const filterMessage =
        filterCount === 1 ? _t('1 filter applied') : _t('#{filterCount} filters applied', { filterCount });
      setFilterStatusMessage(`${resultsMessage}, ${filterMessage}`);
    }, STATUS_ANNOUNCEMENT_DELAY);
  }, [filterCount, totalResults]);

  return (
    <TrackedDiv
      trackingName="degrees_multiselect_filters"
      withVisibilityTracking={true}
      atMostOnce={true}
      trackClicks={false}
      data-testid="degree-filters"
      id="DegreeFilters"
    >
      <Typography variant="h1semibold" component="h2">
        {_t('Find the right degree for you')}
      </Typography>
      <Grid container justifyContent="space-between" css={{ margin: '16px 0 8px' }} wrap="nowrap">
        <Grid item container spacing={16} alignItems="center" css={styles.filterGroup}>
          <Grid item>
            <Typography2 component="p" variant="bodyPrimary">
              {_t('Filter by')}
            </Typography2>
          </Grid>
          {!isLevelHub && (
            <Grid item data-e2e="product-dropdown">
              <MultiSelect
                aria-label={_t('Filter By - Program Level')}
                value={selectedProductVariantValue}
                placeholder={_t('Program Level')}
                variant="silent"
                onOpen={() => {
                  trackComponent({
                    trackingData: {},
                    trackingName: 'multiselect_product_variant_dropdown',
                    action: 'open',
                  });
                }}
                onChange={(values) => {
                  const selected = [...values];
                  const valueStr = selected.length === 0 ? '' : selected.join(',');
                  trackComponent({
                    trackingData: { submittedProductVariant: valueStr },
                    trackingName: 'multiselect_product_variant_dropdown',
                    action: 'change',
                  });
                  selected
                    .filter((value) => !selectedProductVariantValue.includes(value.toString()))
                    .forEach((value) =>
                      trackV3('perform_search', {
                        filter: [`productVariant:${value}`],
                      })
                    );
                  setSubmittedProductVariant(valueStr);
                }}
              >
                {availableProductVariantFilters.map((productVariant) => (
                  <Option key={productVariant}>{getNameFromProductVariant(productVariant)}</Option>
                ))}
              </MultiSelect>
            </Grid>
          )}
          {(!isCategoryHub || isRegional) && (
            <Grid item data-e2e="domain-dropdown">
              <MultiSelect
                aria-label={_t('Filter By - Subject')}
                value={selectedDomainValue}
                placeholder={_t('Subject')}
                variant="silent"
                onOpen={() => {
                  trackComponent({
                    trackingData: {},
                    trackingName: 'multiselect_domain_dropdown',
                    action: 'open',
                  });
                }}
                onChange={(values) => {
                  const selected = [...values];
                  const valueStr = selected.length === 0 ? '' : selected.join(',');
                  trackComponent({
                    trackingData: { submittedDomain: valueStr },
                    trackingName: 'multiselect_domain_dropdown',
                    action: 'change',
                  });
                  selected
                    .filter((value) => !selectedDomainValue.includes(value.toString()))
                    .forEach((value) =>
                      trackV3('perform_search', {
                        filter: [`domain:${value}`],
                      })
                    );
                  setSubmittedDomain(valueStr);
                }}
              >
                {availableDomainFilters.map((domain) => (
                  <Option key={domain.id}>{domain.name}</Option>
                ))}
              </MultiSelect>
            </Grid>
          )}
          {showApplicationOpenFilter && (
            <Grid item data-testid="application-open-dropdown">
              <SilentSelectField
                label={_t('Application Open')}
                labelPlacement="none"
                value={selectedApplicationOpenValue || ''}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setSubmittedApplicationOpen(event?.target.value as string);
                }}
                placeholder={_t('Application Open')}
                onOpen={() => {
                  trackComponent({
                    trackingData: {},
                    trackingName: 'select_application_open_dropdown',
                    action: 'open',
                  });
                }}
              >
                {availableApplicationOpenFilters.map(({ name, id }) => (
                  <SelectOption key={id} value={id}>
                    {name}
                  </SelectOption>
                ))}
              </SilentSelectField>
            </Grid>
          )}
        </Grid>
        <Grid item container alignItems="center" css={styles.allFilter}>
          <Button
            variant="ghost"
            icon={<FilterIcon />}
            iconPosition="after"
            onClick={() => {
              trackComponent({
                trackingName: 'degrees_multiselect_filters_mobile',
                trackingData: {},
                action: 'open',
              });
              setOpen?.(true);
            }}
          >
            {_t('All filters #{filterText}', { filterText })}
          </Button>
          <DegreeFiltersMobile open={open} handleClose={handleClose} degreeFilterState={degreeFilterState} />
        </Grid>
        <Grid item>
          <DegreeEoiButton
            currentPageType={currentPageType}
            slug={slug}
            productId={productId}
            surveyProductType={surveyProductType}
          />
        </Grid>
      </Grid>
      <Grid container spacing={8} alignItems="center">
        <Grid item>
          {hasFiltersSelected && (
            <Typography2 component="h3" variant="subtitleMedium">
              <FormattedHTMLMessage
                message={productCardEpicMessage}
                totalResults={totalResults.toString()}
                productName={selectedProductVariant && <b>{getNameFromProductVariant(selectedProductVariant)}</b>}
                domain={domainName && <b>{domainName}</b>}
                data-e2e="degree-list-title"
              />
            </Typography2>
          )}
          <div className="sr-only" aria-live="polite">
            {hasFiltersSelected && filterStatusMessage}
          </div>
        </Grid>
        {hasFiltersSelected && (
          <Grid item css={styles.resetFilter}>
            <Button data-e2e="reset-filter" variant="ghost" onClick={clearAll}>
              {_t('Reset Filters')}
            </Button>
          </Grid>
        )}
      </Grid>
    </TrackedDiv>
  );
};

export default React.memo(DegreeFilters);
