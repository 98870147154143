import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import Markdown from 'react-markdown';

import type { FaqProps } from 'bundles/cms/types';
import { clearEmptyFields } from 'bundles/seo/utils/utils';

type Props = {
  faqs: Array<FaqProps | null>;
};

class FaqsSchemaMarkup extends React.Component<Props, {}> {
  generatePlainHtmlFromMarkDown = (markdownSource: string | undefined | null) => {
    const plainHtml = renderToStaticMarkup(<Markdown source={markdownSource ?? undefined} />);
    return plainHtml;
  };

  generatePlainTextFromHtml = (html: string) => {
    // Regex for removing html tags and related attributes from a string
    const regex = /(<([^>]+)>)/gi;
    const plainText = html.replace(regex, '');
    return plainText;
  };

  generateBrowseFAQMarkup = () => {
    const { faqs } = this.props;
    return faqs
      .filter((faq): faq is FaqProps => !!faq)
      .map(({ question, answer }) => ({
        '@type': 'Question',
        name: this.generatePlainTextFromHtml(this.generatePlainHtmlFromMarkDown(question)),
        acceptedAnswer: {
          '@type': 'Answer',
          text: this.generatePlainTextFromHtml(this.generatePlainHtmlFromMarkDown(answer)),
        },
      }));
  };

  render() {
    const markupJson = {
      '@context': 'http://schema.org',
      '@type': 'FAQPage',
      mainEntity: this.generateBrowseFAQMarkup(),
    };

    const createMarkup = () => ({
      __html: `
        <script type="application/ld+json">
          ${JSON.stringify(clearEmptyFields(markupJson))}
        </script>
      `,
    });

    return (
      <div className="rc-FaqsSchemaMarkup">
        <div dangerouslySetInnerHTML={createMarkup()} />
      </div>
    );
  }
}

export default FaqsSchemaMarkup;
