/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { Theme } from '@coursera/cds-core';
import { Typography2, useTheme } from '@coursera/cds-core';

import FloatToPercent from 'bundles/primitive-formatting/components/FloatToPercent';
import numberRenders from 'bundles/teach-course/lib/numberRenders';

import _t from 'i18n!nls/reviews-common';

type Props = {
  countsByStar: {
    [starValue: string]: number;
  };
  totalReviewCount: number;
};

const GOLD = '#FFC057';
const GREYISH = '#EAEAEA';

const styles = {
  barGraphContainer: (theme: Theme) => css`
    width: 50%;
    list-style-type: none;
    padding: 0;
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `,
  graphRow: (theme: Theme) => css`
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing(4)};
  `,
  starBarLabel: (theme: Theme) => css`
    flex: 0 0 50px;
    margin-right: ${theme.spacing(8)};
  `,
  barGraphBar: (theme: Theme) => css`
    flex: 0 0 170px;
    height: ${theme.spacing(12)};
    position: relative;
    margin-right: ${theme.spacing(16)};
    margin-top: ${theme.spacing(4)};
    ${theme.breakpoints.down('sm')} {
      flex-shrink: 0.9;
    }
  `,
  numRatings: (theme: Theme) => css`
    flex: ${theme.spacing(0, 0, 112)};
    margin-top: -2px;
    margin-left: -5px;
  `,
  // Ref: https://codepen.io/jedtrow/pen/YPrqKY
  greyBar: css({
    content: '""',
    top: 0,
    left: 0,
    width: '100%',
    position: 'absolute',
    height: '10px',
    background: GREYISH,
  }),
  goldBar: (maxWidthAsPercentage: string) =>
    css({
      content: '""',
      top: 0,
      left: 0,
      position: 'absolute',
      height: '10px',
      background: GOLD,
      display: 'block',
      width: maxWidthAsPercentage,
    }),
};

const ReviewsRatingBarGraph = ({ countsByStar, totalReviewCount }: Props) => {
  const theme = useTheme();

  if (!countsByStar || !totalReviewCount) {
    return null;
  }

  const barGraphs = Object.entries(countsByStar)
    .reverse()
    .map(([starValue, starCount]) => {
      const starCountNum = Number(starCount);
      const percentageAsDecimal = starCountNum / totalReviewCount;
      const starPercentage = numberRenders.Formatters.percentage(percentageAsDecimal);
      const starText = starValue === '1' ? _t('1 star') : _t('#{starValue} stars', { starValue });

      return (
        <li key={`${starValue}-star`} css={styles.graphRow(theme)}>
          <div css={styles.starBarLabel(theme)}>
            <Typography2 component="span" variant="bodyPrimary">
              {starText}
            </Typography2>
          </div>
          <div css={styles.barGraphBar(theme)}>
            <div css={styles.greyBar}>
              <div css={styles.goldBar(starPercentage)} />
            </div>
          </div>
          <div css={styles.numRatings(theme)}>
            <Typography2 component="span" variant="bodyPrimary">
              <FloatToPercent value={percentageAsDecimal} />
            </Typography2>
          </div>
        </li>
      );
    });

  return (
    <div data-unit="reviews-bar-graph">
      <ul css={styles.barGraphContainer(theme)}>{barGraphs}</ul>
    </div>
  );
};

export default ReviewsRatingBarGraph;
