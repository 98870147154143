/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, Typography, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import type { SectionName } from '@coursera/event-pulse-types';

import type { PropsFromWithUserAgent } from 'bundles/page/common/withUserAgent';
import type { Maybe } from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import type { ProductVariantCardsFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';
import CertificatesCategoryCards from 'bundles/premium-hub/components/certificates/CertificatesCategoryCards';
import ProductOfferingCard from 'bundles/premium-hub/components/shared/ProductOfferingCard';
import type { ProductOfferingCardLinkProps } from 'bundles/premium-hub/types';

import _t from 'i18n!nls/premium-hub';

type Props = {
  productsList?: Array<ProductOfferingCardLinkProps>;
  title?: string | null;
  categoryLinks?: Array<Maybe<ProductVariantCardsFragment>>;
  productVariantCards?: Array<Maybe<ProductVariantCardsFragment>>;
  sectionName: SectionName;
} & PropsFromWithUserAgent;

const styles = {
  productOfferingSectionContainer: (theme: Theme) => ({
    maxWidth: '100%',
    marginBottom: theme.spacing(64),
  }),
  width100: {
    width: '100%',
  },
  categoryCardContainer: (theme: Theme) => css`
    padding-top: ${theme.spacing(64)};
    width: '100%';
  `,
};

const ProductOfferingSection: React.FunctionComponent<Props> = ({
  productsList,
  title,
  categoryLinks,
  productVariantCards,
  userAgent,
  sectionName,
}) => {
  const theme = useTheme();

  if (!productsList || !productsList.length) {
    return null;
  }

  return (
    <div data-test="ProductOfferingSection" css={styles.productOfferingSectionContainer(theme)}>
      <Grid container justifyContent="space-between">
        <Grid item lg={8} md={12} css={styles.width100}>
          {title && (
            <Typography
              data-test="ProductListTitle"
              variant="h1semibold"
              component="h2"
              css={{ padding: theme.spacing(48, 0) }}
            >
              {title}
            </Typography>
          )}
          <ul
            css={{
              listStyleType: 'none',
              margin: 0,
              padding: 0,
            }}
          >
            {productsList.map((product, index) => {
              return (
                <ProductOfferingCard
                  key={product.name}
                  entityIndex={index}
                  userAgent={userAgent}
                  {...product}
                  sectionName={sectionName}
                />
              );
            })}
          </ul>
        </Grid>
        <Grid item lg={3} css={styles.categoryCardContainer}>
          <CertificatesCategoryCards
            categoryLinks={categoryLinks}
            productVariantCards={productVariantCards}
            displayVariantCards={true}
            heading={_t('Explore more certificates by category')}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductOfferingSection;
