import * as React from 'react';
import ReactModal from 'react-modal';

import { CloseIcon } from '@coursera/cds-icons';

import keys from 'bundles/phoenix/components/a11y/constants';

import _t from 'i18n!nls/xddp';

import 'css!bundles/xddp/components/__styles__/shared/Overlay';

type Props = {
  children?: JSX.Element;
  handleClose?: () => void;
  isOpen?: boolean;
};

export default class Overlay extends React.Component<Props> {
  modalRef: HTMLElement | null | undefined;

  closeButton: HTMLElement | null | undefined;

  componentDidMount() {
    ReactModal.setAppElement('#rendered-content');
    document.addEventListener('keydown', this.keyListener);

    if (typeof document !== 'undefined' && this.closeButton) {
      this.closeButton.focus();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyListener);
  }

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'e' implicitly has an 'any' type... Remove this comment to see the full error message
  keyListener = (e) => {
    const listener = this.keyListenersMap.get(e.keyCode);
    return listener && listener(e);
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'e' implicitly has an 'any' type... Remove this comment to see the full error message
  handleTabKey = (e) => {
    if (this.modalRef) {
      const focusableModalElements = this.modalRef.querySelectorAll(
        'a[href], button, textarea, input, select, iframe, [tabindex]:not([tabindex="-1"])'
      );

      const firstElement = focusableModalElements[0] as HTMLElement;
      const lastElement = focusableModalElements[focusableModalElements.length - 2] as HTMLElement;
      const activeElement = document.activeElement as HTMLElement;

      if (e.shiftKey && activeElement === firstElement) {
        lastElement.focus();
        e.preventDefault();
      } else if (activeElement === lastElement) {
        firstElement.focus();
        e.preventDefault();
      }
    }
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'e' implicitly has an 'any' type... Remove this comment to see the full error message
  handleFocus = (e) => {
    if (this.modalRef) {
      const focusableModalElements = this.modalRef.querySelectorAll(
        'a[href], button, textarea, input, select, iframe, [tabindex]:not([tabindex="-1"])'
      );

      const firstElement = focusableModalElements[0] as HTMLElement;
      const lastElement = focusableModalElements[focusableModalElements.length - 2] as HTMLElement;

      if (e.shiftKey) {
        lastElement.focus();
        e.preventDefault();
      }

      firstElement.focus();
      e.preventDefault();
    }
  };

  onModalClose = () => {
    const { handleClose } = this.props;

    if (handleClose) {
      handleClose();
    }
  };

  keyListenersMap = new Map([
    [keys.esc, this.onModalClose],
    [keys.tab, this.handleTabKey],
  ]);

  render() {
    const { children, handleClose, isOpen } = this.props;

    return (
      <ReactModal
        bodyOpenClassName="body"
        className="content"
        contentLabel={_t('Video modal')}
        isOpen={isOpen || true}
        overlayClassName="overlay"
        portalClassName="overlay-container rc-Overlay"
        onRequestClose={handleClose}
        shouldCloseOnEsc={true}
      >
        <div
          ref={(ref) => {
            this.modalRef = ref;
          }}
        >
          <button
            type="button"
            className="close-overlay"
            aria-label={_t('Close')}
            onClick={handleClose}
            ref={(rf) => {
              this.closeButton = rf;
            }}
          >
            <CloseIcon size="large" color="invert" />
          </button>
          {children}
          {/* This button is here to help in trapping focus inside the modal (especially on Firefox browsers) */}
          <button type="button" className="focus-trap" aria-hidden="true" onFocus={this.handleFocus} />
        </div>
      </ReactModal>
    );
  }
}
