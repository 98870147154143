/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useState } from 'react';

import { VisuallyHidden } from '@coursera/cds-core';

import AiLoadingIndicatorBar from 'bundles/cds-labs/components/AiLoading/AiLoadingIndicatorBar';

import _t from 'i18n!nls/cds-labs';

const styles = {
  root: css`
    display: inline-flex;
    align-items: center;
    column-gap: 2px;
    vertical-align: middle;
    height: 16px;
  `,
};

type Props = React.HTMLAttributes<HTMLSpanElement>;

// addresses an issue with JAWS + Chrome where the label is
// not announced when the element is rendered conditionally
const useDelayLabelAnnouncment = () => {
  const [renderLabel, setRenderLabel] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setRenderLabel(true);
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return renderLabel;
};

const AiLoadingIndicator: React.FC<Props> = React.memo(
  React.forwardRef<HTMLSpanElement, Props>((props, ref) => {
    const { 'aria-label': label = _t('Loading...'), ...attributes } = props;
    const renderLabel = useDelayLabelAnnouncment();

    return (
      <span ref={ref} role="status" css={styles.root} {...attributes}>
        {renderLabel && <VisuallyHidden>{label}</VisuallyHidden>}
        <AiLoadingIndicatorBar />
        <AiLoadingIndicatorBar animationDelay="0.25s" opacity={0.6} />
        <AiLoadingIndicatorBar animationDelay="0.5s" opacity={0.3} />
      </span>
    );
  })
);

export default AiLoadingIndicator;
