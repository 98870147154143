/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import { Button } from '@coursera/cds-core';
import { useTracker } from '@coursera/event-pulse/react';

import DegreesDiscoveryExperiments from 'bundles/epic/clients/DegreesDiscovery';
import { EoiDialog } from 'bundles/expression-of-interest/components/EoiDialog';
import { EoiOptimizationsContextProvider } from 'bundles/expression-of-interest/components/EoiOptimizationsContext';
import TrackedButton from 'bundles/page/components/TrackedButton';
import type { PremiumHubPageType } from 'bundles/premium-hub/constants';
import { HubPages } from 'bundles/premium-hub/constants';
import { ProductTypes } from 'bundles/university-program-qualification/utils/constants';

import _t from 'i18n!nls/premium-hub';

type Props = {
  slug: string;
  productId: string;
  surveyProductType: string;
  currentPageType?: PremiumHubPageType;
};

const styles = {
  eoiBtn: css`
    white-space: nowrap;
    padding: var(--cds-spacing-100) var(--cds-spacing-200);
  `,
};

const DegreeEoiButton: React.FunctionComponent<Props> = ({ slug, productId, surveyProductType, currentPageType }) => {
  const trackV3 = useTracker();

  const [shouldShowEOISurvey, setShouldShowEOISurvey] = useState<boolean>(false);
  const heroBannerUnificationEpic = DegreesDiscoveryExperiments.preview('heroBannerUnification');
  const showEOIButton =
    currentPageType !== HubPages.DegreesCategoryHubPage &&
    currentPageType !== HubPages.ProductVariantHubPage &&
    heroBannerUnificationEpic !== 'C';

  const completeProductId = tupleToStringKey([surveyProductType, productId]);

  const productType = currentPageType === HubPages.MainHubPage ? ProductTypes.DEGREE_HUB : ProductTypes.DEGREE_CATEGORY;

  return (
    <div>
      {showEOIButton && (
        <Button
          component={TrackedButton}
          variant="secondary"
          onClick={() => {
            setShouldShowEOISurvey(true);
            trackV3('interact_eoi', {
              eoiInteraction: 'open_form',
            });
          }}
          data-testid="email-info-button"
          trackingName="email_info_button"
          withVisibilityTracking={false}
          requireFullyVisible={false}
          css={styles.eoiBtn}
        >
          {_t('Email me info')}
        </Button>
      )}

      <EoiOptimizationsContextProvider slug={slug} productType={productType}>
        <EoiDialog
          isOpen={shouldShowEOISurvey}
          onClose={() => setShouldShowEOISurvey(false)}
          onOpen={() => setShouldShowEOISurvey(true)}
          productId={completeProductId}
        />
      </EoiOptimizationsContextProvider>
    </div>
  );
};

export default React.memo(DegreeEoiButton);
