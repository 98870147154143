/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, Typography2 } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import Markdown from 'bundles/cms/components/Markdown';
import { testimonialSchemaData } from 'bundles/premium-hub/utils/structuredDataUtils';
import GoogleMarkup from 'bundles/seo/components/GoogleMarkup';

type Props = {
  quoteText?: string | null;
  authorName?: string | null;
  authorTitle?: string | null;
  authorOrg?: string | null;
};

const styles = {
  quoteContainer: (theme: Theme) =>
    css`
      padding: ${theme.spacing(32)};
    `,
  leftOpenQuote: (theme: Theme) => css`
    font-size: 80px;
    font-weight: 600;
    line-height: 80px;
    text-align: left;
    ${theme.breakpoints.down('xs')} {
      letter-spacing: 0;
    }
    ${theme.breakpoints.up('sm')} {
      letter-spacing: 16px;
    }
  `,
  textContainer: () => css`
    width: 80%;
  `,
  authorContainer: () => css`
    padding-top: 16px;
  `,
  blockQuote: () => css`
    border-left: none;
    padding-left: 0;
    margin-bottom: 0;
  `,
  quoteText: (theme: Theme) => css`
    .rc-Markdown.styled {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      li {
        font-family: ${theme.typography.fontFamily};
        ${theme.typography.h2}
        color: ${theme.palette.black[700]};
      }

      a {
        color: ${theme.palette.blue[600]};

        &:hover,
        &:focus {
          text-decoration: none;
        }

        &:visited {
          color: ${theme.palette.purple[800]};
        }
      }
    }
  `,
};

export const Quote: React.FunctionComponent<Props> = ({ quoteText, authorName, authorTitle, authorOrg }) => {
  const schemaData = testimonialSchemaData({ quoteText, authorName, authorTitle, authorOrg });

  return (
    <div css={styles.quoteContainer}>
      {schemaData && <GoogleMarkup graph={schemaData} />}
      <Grid container>
        <Grid item>
          <Typography2 component="p" display="inline" css={styles.leftOpenQuote}>
            &ldquo;
          </Typography2>
        </Grid>

        <Grid item css={styles.textContainer}>
          <Grid container item direction="column">
            <Grid item css={styles.quoteText}>
              <blockquote css={styles.blockQuote}>
                <Markdown source={quoteText} trackingName="testimonial_link" />
              </blockquote>
            </Grid>
            <Grid item css={styles.authorContainer}>
              <Typography2 component="h3" variant="subtitleMedium">
                {authorName}
              </Typography2>
            </Grid>
            <Grid item>
              <Typography2 component="p" variant="bodySecondary" color="supportText">
                {authorTitle}
              </Typography2>
            </Grid>
            <Grid item>
              <Typography2 component="p" variant="bodySecondary" color="supportText">
                {authorOrg}
              </Typography2>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Quote;
