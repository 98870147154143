/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography2, type Typography2Props } from '@coursera/cds-core';

import AiLoadingIndicator from 'bundles/cds-labs/components/AiLoading/AiLoadingIndicator';

import _t from 'i18n!nls/cds-labs';

const styles = {
  label: css`
    margin-inline-end: var(--cds-spacing-50);
  `,
};

type Props = Omit<Typography2Props, 'variant' | 'component'> & {
  /**
   * Optional prop to customize the label. Default is "Loading..."
   */
  label?: string;

  /**
   * Optional prop to invert the label color for dark backgrounds.
   */
  inverted?: boolean;

  /**
   * Optional prop to override the default AiIndictoar loading indicator.
   */
  indicator?: React.ReactElement;
};

const AiLoading: React.FC<Props> = React.memo(
  React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { label = _t('Loading...'), indicator, inverted = false, ...attributes } = props;
    const color = inverted ? 'invertBody' : undefined;

    // prevents double announcement of the label from ai loading indicator
    const shouldHideLabel = indicator == null;

    return (
      <Typography2 ref={ref} variant="bodyPrimary" component="div" color={color} {...attributes}>
        <span aria-hidden={shouldHideLabel} css={styles.label}>
          {label}
        </span>
        {indicator || <AiLoadingIndicator aria-label={label} />}
      </Typography2>
    );
  })
);

export default AiLoading;
