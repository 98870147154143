import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('ChipGroup', [
  'label',
  'inlineLabel',
  'group',
]);

const getChipGroupCss = css`
  min-height: 32px;
  gap: var(--cds-spacing-200) var(--cds-spacing-100);

  .${classes.inlineLabel} {
    align-self: center;
  }

  .${classes.group} {
    display: flex;
    flex-wrap: wrap;
    gap: var(--cds-spacing-100) var(--cds-spacing-50);
  }
`;

export default getChipGroupCss;
