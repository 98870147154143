/* @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { processImage } from 'js/lib/imgix';
import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import { Grid, Typography, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import type { CSSProperties } from '@coursera/coursera-ui';

import { EoiOptimizationsContextProvider } from 'bundles/expression-of-interest/components/EoiOptimizationsContext';
import type { Maybe } from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import { EoiWidgetContainer } from 'bundles/premium-hub/components/degrees/EoiWidgetContainer';
import Container from 'bundles/premium-hub/components/shared/Container';
import HubBreadcrumbs from 'bundles/premium-hub/components/shared/HubBreadcrumbs';
import { HUB_TYPE_TO_BANNER_IMAGE, HubPages, ProductType } from 'bundles/premium-hub/constants';
import { ProductTypes } from 'bundles/university-program-qualification/utils/constants';

type Props = {
  slug?: Maybe<string>;
  pageHeading?: Maybe<string>;
  pageSubheading?: Maybe<string>;
  categoryName?: string;
  currentHubPage: (typeof HubPages)[keyof typeof HubPages];
  productType?: (typeof ProductType)[keyof typeof ProductType];
  productId?: string;
  surveyProductType?: string;
};

const styles: Record<string, CSSProperties> = {
  hubBannerWrapper: (bannerColor: string, isEOIPresent: boolean, theme: Theme) => ({
    background: bannerColor,
    width: '100vw',
    maxWidth: '100%',

    '.BannerContainer': {
      position: 'relative',
    },

    '.BannerRow': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(32, 0),
      [`${theme.breakpoints.down('md')}`]: {
        padding: theme.spacing(12, 16),
      },
    },

    ...(!isEOIPresent && {
      '.BannerContentCol': {
        marginBottom: theme.spacing(32),
      },
    }),

    '.BannerEOICol': {
      [`${theme.breakpoints.down('sm')}`]: {
        marginTop: theme.spacing(12),
        width: '100%',
      },
    },
  }),
  bannerLogoContainer: (theme: Theme) => ({
    height: '100%',
    position: 'absolute',
    width: '50%',
    top: 0,
    right: '12px',
    display: 'flex',
    justifyContent: 'flex-end',
    [`${theme.breakpoints.down('sm')}`]: {
      display: 'none',
    },
  }),

  bannerLogo: {
    height: '100%',
    width: 'auto',
  },
};

const getBannerColor = (theme: Theme, currentHubPage: $TSFixMe) => {
  switch (currentHubPage) {
    case HubPages.MainHubPage:
      return theme.palette.blue[900];
    case HubPages.ProductVariantHubPage:
      return theme.palette.blue[100];
    case HubPages.DegreesCategoryHubPage:
      return theme.palette.purple[50];
    default:
      return theme.palette.blue[900];
  }
};

const HubBanner: React.FunctionComponent<Props> = ({
  slug,
  pageHeading,
  pageSubheading,
  categoryName,
  currentHubPage,
  productType,
  productId,
  surveyProductType,
}) => {
  const theme = useTheme();

  if (!pageHeading) {
    return null;
  }

  const logoImage = processImage(HUB_TYPE_TO_BANNER_IMAGE[currentHubPage].toString());
  const bannerColor = getBannerColor(theme, currentHubPage);
  const headingColor = currentHubPage === HubPages.MainHubPage ? 'invertBody' : 'body';

  const completeProductId = tupleToStringKey([surveyProductType || '', productId || '']);
  const eoiProductType =
    currentHubPage === HubPages.MainHubPage ? ProductTypes.DEGREE_HUB : ProductTypes.DEGREE_CATEGORY;

  return (
    <div id="HubBanner" data-test="HubBanner" css={styles.hubBannerWrapper(bannerColor, !!productId, theme)}>
      <Container className="BannerContainer">
        <div css={styles.bannerLogoContainer}>
          <img css={styles.bannerLogo} src={logoImage} alt="" />
        </div>

        <Grid container className="BannerRow">
          <Grid item className="BannerContentCol" xs={12} md={7}>
            {productType !== ProductType.Certificates && (
              <HubBreadcrumbs productName={categoryName || pageHeading} productType={productType} />
            )}
            <Typography
              variant="d2semibold"
              component="h1"
              color={headingColor}
              css={{ margin: theme.spacing(48, 0, 16, 0) }}
            >
              {pageHeading}
            </Typography>
            {pageSubheading && (
              <Typography variant="h2" component="p" color={headingColor}>
                {pageSubheading}
              </Typography>
            )}
          </Grid>

          {productId && completeProductId && slug && (
            <Grid item data-testid="BannerEOIContainer" className="BannerEOICol" md={5} lg={4}>
              <EoiOptimizationsContextProvider slug={slug} productType={eoiProductType}>
                <EoiWidgetContainer productName={categoryName || pageHeading} productId={completeProductId} />
              </EoiOptimizationsContextProvider>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default React.memo(HubBanner);
