/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback } from 'react';

import { compose } from 'recompose';

import { Grid, breakpoints } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import type { CSSProperties } from '@coursera/coursera-ui';

import type { Maybe } from 'bundles/cms/types';
import type { PropsFromWithUserAgent } from 'bundles/page/common/withUserAgent';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import type { ProductVariantCardsFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';
import CertificatesCategoryCards from 'bundles/premium-hub/components/certificates/CertificatesCategoryCards';
import ProductOfferingCard from 'bundles/premium-hub/components/shared/ProductOfferingCard';
import ProductOfferingSection from 'bundles/premium-hub/components/shared/ProductOfferingSection';
import { DISPLAYED_COUNT, ProductType, VARIANT_TO_ELEMENT_ID } from 'bundles/premium-hub/constants';
import type { CertificateProps } from 'bundles/premium-hub/types';
import { withCertificatesContentfulIntegration } from 'bundles/premium-hub/utils/contentfulIntegration';
import { getProductOfferingInfo } from 'bundles/premium-hub/utils/index';

import _t from 'i18n!nls/premium-hub';

type InnerProps = {
  certificatesList?: Array<CertificateProps>;
  title?: string | null;
  categoryLinks?: Array<Maybe<ProductVariantCardsFragment>>;
  productVariantCards?: Array<Maybe<ProductVariantCardsFragment>>;
  productId?: string;
  categorizedCertificates?: {
    [key: string]: {
      title: string;
      slug?: string;
      certificates: Array<CertificateProps>;
    };
  };
} & PropsFromWithUserAgent;

export type ExpandedCertficatesProps = {
  allCertificateData?: Array<CertificateProps & { description?: string; durationDescription?: string }>;
} & InnerProps;

const styles: Record<string, CSSProperties> = {
  productTitle: (theme: Theme) => ({
    fontFamily: 'inherit',
    fontWeight: 400,
    lineHeight: '42px',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },
  }),
  dotsImage: (theme: Theme) => ({
    height: '100%',
    width: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  }),
  certificateListContainer: {
    marginBottom: '60px',
  },
  certificateListHeading: {
    fontFamily: 'inherit',
    lineHeight: '30px',
    fontWeight: 700,
    fontSize: '24px',
    marginBottom: '24px',
  },
  certificateList: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  width100: {
    width: '100%',
  },
  categoryCardContainer: css`
    ${breakpoints.up('md')} {
      padding-left: var(--cds-spacing-400);
    }

    padding-top: var(--cds-spacing-800);
    max-width: 100%;
  `,
};

const ExpandedCertificatesContainer: React.FunctionComponent<ExpandedCertficatesProps> = ({
  userAgent,
  categorizedCertificates,
  allCertificateData,
  productId,
  title,
  categoryLinks,
  productVariantCards,
}) => {
  const sectionName = 'top_certificates_list';

  const renderCategoryPageLink = useCallback(
    (slug: $TSFixMe, count: $TSFixMe, categoryProductId: $TSFixMe, categoryTitle: string) => {
      const categoryListHash = VARIANT_TO_ELEMENT_ID[categoryProductId];

      const categoryHubPath = `/certificates/${slug}#${categoryListHash}`;
      const linkText = _t('View more certificates (#{count})', {
        count,
      });
      const linkA11yText = _t('View #{count} more certificates to #{categoryTitle}', {
        count,
        categoryTitle,
      });

      return (
        <div className="m-t-2">
          <TrackedLink2
            className="font-md font-weight-bold"
            trackingName="degree_category_link"
            href={categoryHubPath}
            data-e2e="degree-category-link"
            aria-label={linkA11yText}
          >
            {linkText}
          </TrackedLink2>
        </div>
      );
    },
    []
  );

  const renderProductCategoriesList = useCallback(() => {
    if (!categorizedCertificates) {
      return null;
    }

    return Object.keys(categorizedCertificates).map((categorySlug) => {
      if (!categorizedCertificates || !categorizedCertificates[categorySlug]) {
        return null;
      }

      const { title: categoryTitle, slug, certificates } = categorizedCertificates[categorySlug];

      if (certificates.length === 0) {
        return null;
      }

      const CERTIFICATES_TO_DISPLAY = slug ? certificates.slice(0, DISPLAYED_COUNT) : certificates;

      return (
        <div css={styles.certificateListContainer} className="rc-ExpandedCertsList" key={categorySlug}>
          <h3 css={styles.certificateListHeading}>{categoryTitle}</h3>
          <ul css={styles.certificateList} data-e2e="certs-list" id={VARIANT_TO_ELEMENT_ID[categorySlug]}>
            {CERTIFICATES_TO_DISPLAY.map((product, index) => {
              const productType = ProductType.Certificates;
              const productWithContentfulInfo = allCertificateData?.find(
                (certificate: { slug: string }) => certificate.slug === product.slug
              );
              const productOfferingInfo = getProductOfferingInfo(productWithContentfulInfo, productType);

              return (
                <ProductOfferingCard
                  key={product.slug}
                  entityIndex={index}
                  userAgent={userAgent}
                  {...productOfferingInfo}
                  sectionName={sectionName}
                />
              );
            })}
          </ul>
          {slug && renderCategoryPageLink(slug, certificates.length, productId, categoryTitle)}
        </div>
      );
    });
  }, [categorizedCertificates, renderCategoryPageLink, productId, allCertificateData, userAgent]);

  if (!allCertificateData || !allCertificateData.length) {
    return null;
  }

  const productsList = allCertificateData.map((certificate) =>
    getProductOfferingInfo(certificate, ProductType.Certificates)
  );

  if (!categorizedCertificates) {
    return (
      <Grid container>
        <ProductOfferingSection
          title={title}
          productsList={productsList}
          categoryLinks={categoryLinks}
          productVariantCards={productVariantCards}
          userAgent={userAgent}
          sectionName={sectionName}
        />
      </Grid>
    );
  }

  return (
    <div className="rc-ExpandedCertificatesContainer">
      <Grid container>
        <Grid container>
          <Grid item lg={8} md={12}>
            <h2 css={styles.productTitle} className="m-y-3">
              {title}
            </h2>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item md={8} css={styles.width100}>
            {renderProductCategoriesList()}
          </Grid>
          <Grid item md={4} css={styles.categoryCardContainer}>
            <CertificatesCategoryCards
              categoryLinks={categoryLinks}
              productVariantCards={productVariantCards}
              displayVariantCards={true}
              heading={_t('Explore more certificates by category')}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export const BaseComp = ExpandedCertificatesContainer;

export default compose<InnerProps, ExpandedCertficatesProps>(withCertificatesContentfulIntegration)(
  ExpandedCertificatesContainer
);
