import React, { useState } from 'react';

import clsx from 'clsx';

import { useId } from '@coursera/cds-common';
import { ErrorIcon, SuccessOutlineIcon } from '@coursera/cds-icons';

import type { InputProps } from '@core/forms';
import {
  InputAdornment,
  FormControl,
  FormLabel,
  FormSupportText,
  FormValidationLabel,
  Input,
} from '@core/forms';
import type { BaseFormControlProps } from '@core/forms/FormControl';
import type {
  OptionalInputLabelProps,
  RequireIndicatorProps,
} from '@core/forms/FormLabel';
import { ariaLabelledByForMultipleLabels } from '@core/utils/a11y';

import passwordFieldCss, { classes } from './passwordFieldCss';
import VisibilityToggle from './VisibilityToggle';

export type Props = {
  /**
   * Defines ref passed to the `input` element.
   */
  inputRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement | null>;
  /**
   * Label to describe validation state of the PasswordField.
   */
  validationLabel?: string;
  /**
   * It prevents the user from changing the value of the field
   * (not from interacting with the field).
   * @default false
   */
  readOnly?: boolean;
  /**
   * By default, autocomplete is set to off.
   * This can be set to new-password or current-password to help browsers differentiate between new and current passwords.
   */
  autoComplete?: 'new-password' | 'current-password' | 'off';
} & OptionalInputLabelProps &
  RequireIndicatorProps &
  BaseFormControlProps &
  Pick<
    InputProps,
    | 'onBlur'
    | 'onFocus'
    | 'onChange'
    | 'maxRows'
    | 'prefix'
    | 'suffix'
    | 'inputProps'
    | 'placeholder'
    | 'value'
    | 'name'
    | 'autoFocus'
    | 'multiline'
  >;

const PasswordField = React.forwardRef<HTMLDivElement, Props>(
  function PasswordField(props, ref) {
    const {
      autoComplete = 'off',
      className,
      label,
      renderLabel,
      inputRef,
      id: idFromProps,
      supportText,
      validationLabel,
      validationStatus,
      readOnly,
      fullWidth,
      disabled,
      invert,
      optional,
      onChange,
      inputProps,
      necessityIndicator,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      ...rest
    } = props;

    const [passwordVisible, setPasswordVisible] = useState(false);

    const id = useId(idFromProps);
    const supportTextId = supportText ? `${id}-support-text` : undefined;
    const validationLabelId =
      validationStatus && validationLabel
        ? `${id}-validation-label`
        : undefined;

    return (
      <FormControl
        ref={ref}
        className={clsx(
          {
            [classes.readOnly]: readOnly,
            [classes.valid]: validationStatus === 'success',
            [classes.invalid]: validationStatus === 'error',
          },
          className
        )}
        css={passwordFieldCss}
        disabled={disabled}
        fullWidth={fullWidth}
        id={id}
        invert={invert}
        optional={optional}
        supportText={supportText}
        validationStatus={validationStatus}
      >
        {(label || renderLabel) && (
          <FormLabel
            necessityIndicator={
              !necessityIndicator && optional ? 'text' : necessityIndicator
            }
            renderLabel={renderLabel}
          >
            {label}
          </FormLabel>
        )}

        {supportText && (
          <FormSupportText className={classes.formSupportText}>
            {supportText}
          </FormSupportText>
        )}

        {validationStatus && validationLabel && (
          <FormValidationLabel
            hideIcon
            className={classes.formValidationLabel}
            label={validationLabel}
          />
        )}

        <Input
          {...rest}
          autoComplete={autoComplete}
          classes={{
            root: classes.root,
            focused: classes.focused,
          }}
          fullWidth={fullWidth}
          inputProps={{
            'aria-label': ariaLabel || label,
            'aria-labelledby': ariaLabelledBy,
            'aria-describedby': ariaLabelledByForMultipleLabels(
              validationLabelId,
              supportTextId
            ),
            ...inputProps,
          }}
          inputRef={inputRef}
          readOnly={readOnly}
          suffix={
            <>
              {validationStatus === 'success' && (
                <InputAdornment
                  aria-hidden
                  className={classes.validationIcon}
                  data-testid="success-suffix"
                  position="end"
                  validationStatus="success"
                  verticallyAligned="center"
                >
                  <SuccessOutlineIcon size="medium" />
                </InputAdornment>
              )}
              {validationStatus === 'error' && (
                <InputAdornment
                  aria-hidden
                  className={classes.validationIcon}
                  data-testid="error-suffix"
                  position="end"
                  validationStatus="error"
                  verticallyAligned="center"
                >
                  <ErrorIcon size="medium" />
                </InputAdornment>
              )}

              <VisibilityToggle
                aria-controls={id}
                disabled={disabled}
                setVisibility={setPasswordVisible}
                visible={passwordVisible}
              />
            </>
          }
          type={passwordVisible ? 'text' : 'password'}
          onChange={onChange}
        />
      </FormControl>
    );
  }
);

export default PasswordField;
