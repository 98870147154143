/* @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { Typography, Typography2, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import type { CSSProperties } from '@coursera/coursera-ui';

import type { CommunityStatsFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';

const styles: Record<string, CSSProperties> = {
  statsInfoCard: (theme: Theme) => ({
    height: 278,
    minHeight: 242,
    margin: theme.spacing(8, 4),
    padding: theme.spacing(16),
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.blue[100],
  }),
  description: {
    lineHeight: '1rem',
  },
  highlightLabel: (theme: Theme) => ({
    marginBottom: theme.spacing(8),
  }),
};

const CommunityStats: React.FunctionComponent<CommunityStatsFragment> = ({ highlightLabel, highlightStats }) => {
  const theme = useTheme();

  return (
    <div css={styles.statsInfoCard(theme)}>
      <div>
        <Typography2 variant="bodyPrimary" component="p" css={styles.highlightLabel(theme)}>
          <strong>{highlightLabel}</strong>
        </Typography2>
        <Typography variant="d2semibold" component="p" css={styles.description}>
          <strong>{highlightStats}</strong>
        </Typography>
      </div>
    </div>
  );
};

export default CommunityStats;
