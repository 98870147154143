/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import type { Interpolation } from '@emotion/react';

import * as React from 'react';

import { Grid } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

type Props = {
  children: React.ReactNode;
  className?: string;
  css?: Interpolation<Theme>;
};

const styles: Record<string, Interpolation<Theme>> = {
  pageContainer: (theme: Theme) => css`
    position: relative;
    margin: 0 auto;
    padding: 0 12px;
    width: 100%;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 1152px;
    }

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      max-width: 1440px;
    }
  `,
};

function Container({ children, className, css: cssProp }: Props): React.ReactElement {
  return (
    <Grid css={[styles.pageContainer, cssProp]} className={className}>
      {children}
    </Grid>
  );
}

export default React.memo(Container);
