/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import PropTypes from 'prop-types';

import Retracked from 'js/app/retracked';

import type { Theme } from '@coursera/cds-core';
import { Typography2 } from '@coursera/cds-core';
import { ChevronDownIcon } from '@coursera/cds-icons';
import { track } from '@coursera/event-pulse/sdk';

import { sourceSansPro } from 'bundles/front-page/constants/rebrand';
import PageHeaderContext from 'bundles/page-header/contexts/PageHeaderContext';
import TrackedButton from 'bundles/page/components/TrackedButton';

import _t from 'i18n!nls/page-header';

type Props = {
  menuIsOpen: boolean;
  openMenu: () => void;
  openMenuUsingKeyboard: () => void;
  closeMenu: () => void;
  setAnchorElement?: (anchorElement: HTMLElement) => void;
  setExploreButtonRef: (buttonRef: HTMLButtonElement | null) => void;
};

const styles = {
  exploreButtonRoot: (theme: Theme) => css`
    margin: auto 0;
    background: none;
    border: none;
    height: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: ${theme.palette.blue[600]};
    font-weight: bold;
    cursor: default;
    padding: 0;
    position: relative;
    color: var(--cds-color-white-0);
  `,
  normalBtnLabel: () => css`
    font-family: ${sourceSansPro};
    font-size: inherit;
    height: 36px;
    top: 0;
    width: 100%;
    line-height: 36px;
    text-align: center;
    position: relative;
    padding-left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: inherit;
    }

    @media screen and (max-width: 1400px) {
      padding-left: 2px;
    }
  `,
  menuIsOpen: (theme: Theme) => css`
    box-shadow: #e4e4e4 0 1px 6px, #e4e4e4 0 1px 4px;
    background-color: transparent;
    height: 100%;
    cursor: default;
    color: ${theme.palette.blue[600]};
  `,
  arrow: () => css`
    margin-left: 6px;
  `,
  preUnifiedExploreButton: (theme: Theme) => css`
    background-color: var(--cds-color-neutral-background-primary);
    color: var(--cds-color-interactive-primary);
    border: 1px solid var(--cds-color-interactive-primary);
    min-width: max-content;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: default;
    padding: 6px ${theme.spacing(16)};

    :hover,
    :focus,
    :active {
      color: var(--cds-color-interactive-primary-hover);
      background-color: var(--cds-color-interactive-background-primary-hover-weak);
      border-color: var(--cds-color-interactive-primary-hover);
    }
  `,
  preUnifiedBtnLabel: css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  `,
  openedExplore: (theme: Theme) => css`
    color: ${theme.palette.blue[600]};
  `,
  closedExplore: (theme: Theme) => css`
    color: ${theme.palette.black};
  `,
};

class ExploreButton extends React.Component<Props> {
  static contextTypes = {
    _eventData: PropTypes.object,
  };

  toggleMenu = () => {
    const { openMenuUsingKeyboard, menuIsOpen, closeMenu } = this.props;
    if (!menuIsOpen) {
      track('open_navigation_menu', {
        navigationType: 'megamenu',
      });

      // comment TBA
      openMenuUsingKeyboard();
    } else {
      closeMenu();
    }
  };

  onKeyUp = (evt: React.KeyboardEvent<unknown>) => {
    const { menuIsOpen } = this.props;
    switch (evt.key) {
      case ' ':
        // because space does not trigger retracked click event like enter
        Retracked.trackComponent(this.context?._eventData, { menuIsOpen }, 'explore_button', 'click');
        evt.preventDefault();
        this.toggleMenu();
        break;
      case 'Enter':
        evt.preventDefault();
        this.toggleMenu();
        break;
      default:
    }
  };

  onSetAnchorElement = (anchorElement?: HTMLElement | null) => {
    const { setAnchorElement } = this.props;
    if (anchorElement && setAnchorElement) {
      setAnchorElement(anchorElement);
    }
  };

  openMenuWithTracking = () => {
    const { openMenu } = this.props;
    openMenu();

    // adding hover action here because trackedButton doesn't support tracking hover
    Retracked.trackComponent(this.context?._eventData, {}, 'explore_button', 'hover');
    track('open_navigation_menu', {
      navigationType: 'megamenu',
    });
  };

  closeMenu = () => {
    const { closeMenu } = this.props;
    closeMenu();
    Retracked.trackComponent(this.context?._eventData, {}, 'explore_button', 'leave');
  };

  renderSvgArrow = (menuIsOpen: boolean, preUnifiedHeader: boolean) => {
    const iconFill = menuIsOpen ? '#2073d4' : '#fff';

    if (preUnifiedHeader) {
      return <ChevronDownIcon size="small" width="12" height="12" />;
    }
    return (
      <svg className="rc-ExploreButton__arrow" css={styles.arrow} viewBox="0 0 32 32" width="9" height="9">
        <path
          fill={iconFill}
          d="M30.054 14.429l-13.25 13.232q-0.339 0.339-0.804 0.339t-0.804-0.339l-13.25-13.232q-0.339-0.339-0.339-0.813t0.339-0.813l2.964-2.946q0.339-0.339 0.804-0.339t0.804 0.339l9.482 9.482 9.482-9.482q0.339-0.339 0.804-0.339t0.804 0.339l2.964 2.946q0.339 0.339 0.339 0.813t-0.339 0.813z"
        />
      </svg>
    );
  };

  render() {
    const { menuIsOpen, openMenu, setExploreButtonRef } = this.props;

    return (
      <PageHeaderContext.Consumer>
        {({ isSimplifiedPageHeader }) => {
          return (
            <TrackedButton
              trackingName="explore_button"
              className={`rc-ExploreButton${menuIsOpen ? ' menuIsOpen' : ''}`}
              css={[
                isSimplifiedPageHeader ? styles.preUnifiedExploreButton : styles.exploreButtonRoot,
                menuIsOpen && styles.menuIsOpen,
              ]}
              onMouseEnter={this.openMenuWithTracking}
              onMouseLeave={this.closeMenu}
              onKeyUp={this.onKeyUp}
              onMouseDown={openMenu}
              aria-expanded={menuIsOpen}
              aria-haspopup={true}
              aria-label="Explore our catalog"
              data-e2e="megamenu-explore-button"
              onSetRef={setExploreButtonRef}
              data={{ menuIsOpen }}
            >
              <span
                css={isSimplifiedPageHeader ? styles.preUnifiedBtnLabel : styles.normalBtnLabel}
                ref={this.onSetAnchorElement}
              >
                <Typography2
                  component="span"
                  variant="actionPrimary"
                  css={menuIsOpen ? styles.openedExplore : styles.closedExplore}
                >
                  {_t('Explore')}
                </Typography2>
                {this.renderSvgArrow(menuIsOpen, !!isSimplifiedPageHeader)}
              </span>
            </TrackedButton>
          );
        }}
      </PageHeaderContext.Consumer>
    );
  }
}
export default ExploreButton;
