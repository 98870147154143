/** @jsxFrag React.Fragment */

/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { breakpoints } from '@coursera/cds-core';

import DegreeFilters from 'bundles/premium-hub/components/degrees/DegreeFilters';
import DegreeHubScrollAnchor from 'bundles/premium-hub/components/degrees/DegreeHubScrollAnchor';
import TopDegreesList from 'bundles/premium-hub/components/degrees/TopDegreesList';
import type { DegreeFilterState } from 'bundles/premium-hub/components/degrees/useMultiSelectDegreeFilterStateManager';
import Container from 'bundles/premium-hub/components/shared/Container';
import { type PremiumHubPageType } from 'bundles/premium-hub/constants';
import type { Domain, PremiumProductWithMetadata } from 'bundles/premium-hub/types/index';

type Props = {
  degreeFilterState: DegreeFilterState;
  slug: string;
  categoryName?: string;
  currentPageType?: PremiumHubPageType;
  domains: Domain[];
  filteredDegreeList: Array<PremiumProductWithMetadata>;
  degreeListWithMetadata?: PremiumProductWithMetadata[];
};

const TopDegreeListSection = ({
  degreeFilterState,
  slug,
  categoryName,
  currentPageType,
  domains,
  filteredDegreeList,
  degreeListWithMetadata,
}: Props) => {
  if (!degreeListWithMetadata?.length) {
    return null;
  }

  return (
    <div
      css={css`
        margin-top: var(--cds-spacing-400);

        ${breakpoints.down('sm')} {
          margin-top: var(--cds-spacing-150);
        }
      `}
    >
      <Container>
        <DegreeFilters
          degreeFilterState={degreeFilterState}
          slug={slug ?? ''}
          productId="degrees"
          surveyProductType="GenericPage"
          categoryName={categoryName}
          currentPageType={currentPageType}
          domains={domains ?? []}
          degrees={filteredDegreeList ?? []}
        />
        <DegreeHubScrollAnchor />
        <div
          css={css`
            ${breakpoints.down('sm')} {
              padding: 0 var(--cds-spacing-150);
            }
          `}
        >
          <TopDegreesList degrees={filteredDegreeList ?? []} />
        </div>
      </Container>
    </div>
  );
};

export default React.memo(TopDegreeListSection);
