/* @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';
import type { RefObject } from 'react';

import imgixLib from 'js/lib/imgix';

import { breakpoints } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import { SvgPlayFilled } from '@coursera/coursera-ui/svg';
import { useTracker } from '@coursera/event-pulse/react';

import TrackedDiv from 'bundles/page/components/TrackedDiv';
import type { Maybe } from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import type {
  PremiumHubAudioDescriptionAssetFragment,
  PremiumHubImageAssetFragment,
  PremiumHubVideoAssetFragment,
} from 'bundles/premium-hub/__generated__/contentfulTypes';

import _t from 'i18n!nls/degree-description';

type Props = {
  videoFile: PremiumHubVideoAssetFragment;
  audioDescriptionTrack?: Maybe<PremiumHubAudioDescriptionAssetFragment>;
  placeholderImage: PremiumHubImageAssetFragment;
  index: number;
  authorName?: string | null;
  authorTitle?: string | null;
  videoElementRef?: RefObject<HTMLVideoElement>;
  text?: string | null;
};

const styles = {
  videoContainer: css`
    position: relative;
    overflow: hidden;
    content-visibility: auto;
    border-radius: 8px 8px 0 0;
    background-color: var(--cds-color-neutral-primary);
    aspect-ratio: 16 / 9;

    ${breakpoints.up('md')} {
      border-radius: 8px 0 0 8px;
      aspect-ratio: unset;
      height: 434px;
    }
  `,

  video: css`
    position: absolute;
    width: 100%;
    height: 100%;
    margin: auto;
  `,

  videoOverlay: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  blackOverlay: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: var(--cds-color-grey-975);
    opacity: 0.5;
  `,

  playIcon: (theme: Theme) => css`
    border: 3px solid var(--cds-color-white-0);
    border-radius: 36px;
    width: 66px;
    height: 66px;
    padding: 14px 14px;
    margin: 0 auto;
    z-index: 3;

    :hover,
    :active,
    :focus {
      background-color: initial;
    }
  `,
};

const TestimonialVideo: React.FC<Props> = ({
  videoFile,
  videoElementRef,
  audioDescriptionTrack,
  placeholderImage,
  index,
  authorName,
  authorTitle,
  text,
}) => {
  const [videoPlayed, setVideoPlayed] = useState(false);
  const trackV3 = useTracker();

  if (!videoFile.url || !placeholderImage) {
    return null;
  }

  const { url, width, height } = videoFile;

  const compressedImageUrl = imgixLib.processImage(placeholderImage.url, {
    auto: 'format,compress',
    width: width || 640,
    height: height || 360,
    quality: 60,
  });

  const testimonialCardTrackingData = {
    author: authorName ?? '',
    text: text ?? '',
    index: index ?? -1,
  };

  const handlePlayVideo = () => {
    videoElementRef?.current?.play();
    setVideoPlayed(true);
    trackV3('click_testimonial_card', {
      testimonialCard: testimonialCardTrackingData,
      testimonialAction: 'play_video',
    });
  };

  return (
    <div css={styles.videoContainer}>
      <video
        crossOrigin="anonymous"
        tabIndex={videoPlayed ? 0 : -1} // -1 to prevent tabbing to video before it's played
        ref={videoElementRef}
        css={styles.video}
        src={url}
        controls
        controlsList="nodownload"
        onPause={() =>
          trackV3('click_testimonial_card', {
            testimonialCard: testimonialCardTrackingData,
            testimonialAction: 'pause_video',
          })
        }
      >
        {audioDescriptionTrack && audioDescriptionTrack.url && (
          <track label="Audio description" kind="descriptions" srcLang="en" src={audioDescriptionTrack.url} />
        )}
      </video>

      {!videoPlayed && (
        <TrackedDiv
          trackingName="testimonial"
          data={{ indexPosition: index + 1, testimonialName: authorName ?? '', testimonialTitle: authorTitle ?? '' }}
          withVisibilityTracking
          onClick={handlePlayVideo}
          role="button"
          aria-label={_t('Play testimonial video, #{description}', { description: placeholderImage.description })}
          css={[
            styles.videoOverlay,
            css`
              background-image: url(${compressedImageUrl});
            `,
          ]}
          tabIndex={0}
        >
          <div css={styles.playIcon}>
            <SvgPlayFilled size={36} color="white" />
          </div>
          <div css={styles.blackOverlay} />
        </TrackedDiv>
      )}
    </div>
  );
};
export default TestimonialVideo;
