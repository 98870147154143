import { css } from '@emotion/react';

import type { Theme } from '@coursera/cds-core';

const formStyles = {
  formGroup: css`
    margin-bottom: 28px;

    @media (max-width: 767px) {
      display: block;
    }
  `,
  labelWidth: (theme: Theme) => css`
    width: 230px;
    margin-right: 20px;
    flex-grow: 0;

    ${theme.breakpoints.down('sm')} {
      width: 160px;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  `,
  formLabel: css`
    text-align: right;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0;

    @media (max-width: 767px) {
      text-align: left;
    }

    @media (min-width: 767px) {
      /* Override to ensure form fields built with CDS components are visually consistent with legacy ones */
      .rc-AdditionalDetailsCont & {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
      }
    }
  `,
  formControl: css`
    max-width: 560px;
    width: 100%;
  `,
  hideChildLabel: css`
    label {
      display: none;
      visibility: hidden;
      position: absolute !important;
      height: 0;
      width: 0;
    }
  `,
  removeTopMarginImmediateChildDiv: css`
    & > div {
      margin-top: 0 !important;
    }
  `,
  removeChildInputBottomMargin: css`
    input {
      margin-bottom: 0 !important;
    }
  `,
  textArea: css`
    width: 100%;
    margin-bottom: 0;
  `,
  recruitingFormlabelWidth: (theme: Theme) => css`
    width: 200px;
    margin-right: 20px;
    flex-grow: 0;

    ${theme.breakpoints.down('sm')} {
      width: 160px;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  `,
  recruitingFormLabel: css`
    text-align: left;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0;

    @media (max-width: 767px) {
      text-align: left;
    }
  `,
};

export default formStyles;
