/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';
import type { Interpolation } from '@emotion/react';

import { findLast } from 'lodash';

import config from 'js/app/config';
import appInfo from 'js/lib/appInfo';
import { FormattedMessage } from 'js/lib/coursera.react-intl';
import useRouter from 'js/lib/useRouter';
import user from 'js/lib/user';

import type { Theme } from '@coursera/cds-core';
import { Grid, Link, PageGridContainer } from '@coursera/cds-core';
import type { MetaNavValue } from '@coursera/event-pulse-types';
import { useTracker } from '@coursera/event-pulse/react';

import type { DOMAINS } from 'bundles/ent-website/constants/navigation';
import { eventingTransform } from 'bundles/ent-website/utils/eventingTransform';
import { onClickNavUrl } from 'bundles/ent-website/utils/utm';
import { TrackedA } from 'bundles/page/components/TrackedLink2';

import _t from 'i18n!nls/ent-website';

const SOURCE_SANS = `'Source Sans Pro', Arial, sans-serif`;

const ENT_WEBSITE_BUNDLE_NAME = 'ent-website';

export const isEntWebsite = (pathname?: string): boolean => {
  return (
    pathname?.match(/(ent-website|business|government|campus)(\/)?/gi) != null ||
    appInfo.get()?.appName === ENT_WEBSITE_BUNDLE_NAME
  );
};

export const addUtmInfo = (link: string, domain: string, pathname?: string): string => {
  // Individual has no utm, ent website should not have UTM: https://coursera.atlassian.net/browse/ENTING-952
  if (domain === '' || isEntWebsite(pathname)) {
    return link;
  }

  const modifiedLink = new URL(link);
  let utmContent = '';
  const utmCampaign = 'website';
  const utmMedium = 'coursera';
  const utmSource = 'header';
  const loggedIn = user.isAuthenticatedUser();
  const utmTerm = `b-${loggedIn ? 'in' : 'out'}`;

  switch (domain) {
    case '/government':
      utmContent = 'corp-to-landing-for-government';
      break;
    case '/campus':
      utmContent = 'corp-to-landing-for-campus';
      break;
    case '/business':
    default:
      utmContent = 'corp-to-home-for-enterprise';
      break;
  }

  modifiedLink.searchParams.set('utm_content', utmContent);
  modifiedLink.searchParams.set('utm_campaign', utmCampaign);
  modifiedLink.searchParams.set('utm_medium', utmMedium);
  modifiedLink.searchParams.set('utm_source', utmSource);
  modifiedLink.searchParams.set('utm_term', utmTerm);

  return modifiedLink.toString();
};

type AdditionalChecks = {
  domain?: keyof typeof DOMAINS;
  forceOverride?: boolean;
};

type Props = {
  isFluid?: boolean;
  isCustomGrid?: boolean;
} & AdditionalChecks;

export function isMetaNavEnabled(additionalChecks?: AdditionalChecks) {
  const forceOverride = additionalChecks?.forceOverride;

  return forceOverride !== false;
}

export const getMetaHeight = (additionalChecks?: AdditionalChecks) => {
  return isMetaNavEnabled(additionalChecks) ? '40px' : '0px';
};

export function MetaNavigation({ isFluid, isCustomGrid, domain, forceOverride }: Props) {
  const router = useRouter();
  const track = useTracker();

  if (!isMetaNavEnabled({ domain, forceOverride })) {
    return null;
  }

  const NAV_ITEM_PADDING = '8px';
  const styles: Record<string, Interpolation<Theme>> = {
    container: (theme) => css`
      position: relative;
      top: 0;
      width: 100%;
      background: ${theme.palette.black[700]};
      z-index: 999;
      height: calc(${getMetaHeight({ domain, forceOverride })} + 16px);

      ${theme.breakpoints.down('md')} {
        height: calc(40px + 16px);
      }
      ${theme.breakpoints.down('sm')} {
        height: calc(32px + 16px);
      }
      ${theme.breakpoints.down('xs')} {
        height: calc(32px + 12px);
      }
    `,
    gridCustomContainer: css`
      padding-left: 16px !important;
      padding-right: 16px !important;
    `,
    fluidGridContainer: css`
      max-width: none;
    `,
    navListContainer: css`
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    `,
    navScroller: css`
      overflow: hidden;
      height: ${getMetaHeight({ domain, forceOverride })};
    `,
    navList: css`
      margin-left: 0;
      margin-right: -${NAV_ITEM_PADDING};
      margin-bottom: 0;
      padding-left: 0;
      display: flex;
      list-style: none;
      overflow-x: scroll;
    `,
    navListItemOuter: css`
      display: inherit;
    `,
    forStyle: (theme) => css`
      color: ${theme.palette.gray[300]};
      font-weight: 400;
    `,
    navListItem: (theme) => css`
      margin: 0 ${NAV_ITEM_PADDING};
      padding: 0 ${NAV_ITEM_PADDING};
      line-height: ${getMetaHeight({ domain, forceOverride })};
      height: ${getMetaHeight({ domain, forceOverride })};
      font-size: 16px;
      text-decoration: none !important;
      font-family: ${SOURCE_SANS};
      color: var(--cds-color-white-0) !important;
      box-sizing: border-box;
      border-bottom: 6px solid transparent;
      position: relative;

      &:hover,
      &:focus {
        background: var(--cds-color-white-0) !important;
        color: ${theme.palette.black[700]} !important;

        .forStyle {
          color: inherit;
        }
      }
    `,
    navListItemActive: (theme) => css`
      border-bottom: 6px solid var(--cds-color-white-0);
      font-weight: 600;
    `,
  };

  const generateMetaNavItems = () => [
    {
      key: 'Individuals',
      label: (
        <FormattedMessage
          message={_t('{for} Individuals')}
          for={
            <span className="forStyle" css={styles.forStyle}>
              {_t('For')}
            </span>
          }
        />
      ),
      link: `${config.url.base}`,
      domain: '',
      metaNavValue: 'for_individuals',
    },
    {
      key: 'Business',
      label: (
        <FormattedMessage
          message={_t('{for} Businesses')}
          for={
            <span className="forStyle" css={styles.forStyle}>
              {_t('For')}
            </span>
          }
        />
      ),
      link: `${config.url.base}business`,
      domain: '/business',
      metaNavValue: 'for_businesses',
    },
    {
      key: 'Campus',
      label: (
        <FormattedMessage
          message={_t('{for} Universities')}
          for={
            <span className="forStyle" css={styles.forStyle}>
              {_t('For')}
            </span>
          }
        />
      ),
      link: `${config.url.base}campus`,
      domain: '/campus',
      metaNavValue: 'for_universities',
    },
    {
      key: 'Government',
      label: (
        <FormattedMessage
          message={_t('{for} Governments')}
          for={
            <span className="forStyle" css={styles.forStyle}>
              {_t('For')}
            </span>
          }
        />
      ),
      link: `${config.url.base}government`,
      domain: '/government',
      metaNavValue: 'for_governments',
    },
  ];

  const onNavItemClick = (link: string, metaNavValue: MetaNavValue) => {
    track('click_meta_nav', {
      metaNavValue,
    });

    onClickNavUrl(router, link);
  };
  const pathname = router.location.pathname;
  const metaNavItems = generateMetaNavItems();
  const activeDomain = findLast(metaNavItems, (item) => pathname.indexOf(item.domain) === 0);

  return (
    <div css={styles.container}>
      <PageGridContainer
        maxWidth="lg"
        css={[isCustomGrid ? styles.gridCustomContainer : null, isFluid ? styles.fluidGridContainer : null]}
      >
        <Grid item xs={12}>
          <div css={styles.navListContainer}>
            <div role="navigation" aria-label={_t('Banner')} css={styles.navScroller}>
              <ul css={styles.navList}>
                {metaNavItems.map(({ key, label, link, domain, metaNavValue }) => {
                  const isActive = activeDomain?.domain === domain;
                  const trackingName = eventingTransform(`navigation_meta_nav_${key}`);
                  const modifiedLink = addUtmInfo(link, domain, router.location.pathname);
                  return (
                    <li css={styles.navListItemOuter} key={key}>
                      <Link
                        component={TrackedA}
                        trackingName={trackingName}
                        variant="quiet"
                        href={modifiedLink}
                        key={key}
                        onClick={() => onNavItemClick(modifiedLink, metaNavValue as MetaNavValue)}
                        aria-current={isActive ? 'page' : undefined}
                        css={[styles.navListItem, isActive ? styles.navListItemActive : null]}
                      >
                        {label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </Grid>
      </PageGridContainer>
    </div>
  );
}
