import user from 'js/lib/user';

import type { PhoneDetails } from 'bundles/expression-of-interest/utils/PhoneUtils';
import { getPhoneNumberFormat, getPhoneNumberInput } from 'bundles/expression-of-interest/utils/PhoneUtils';
import { getUserProfile } from 'bundles/expression-of-interest/utils/util';
import { QuestionTypes } from 'bundles/survey-form/constants/SurveyFormConstants';
import type { SurveyResponse } from 'bundles/survey-form/constants/SurveyFormConstants';
import { getResponseTypeFromQuestionType } from 'bundles/survey-form/utils/SurveyFormUtils';

export const SHORT_ANSWER_TYPE = getResponseTypeFromQuestionType(QuestionTypes.SHORT_ANSWER);

type PhoneNumberInput = {
  phoneNumber: {
    [x: string]: {
      text: PhoneDetails;
    };
  };
};

export const getSplitName = (fullName?: string) => {
  if (!fullName) {
    return {};
  }

  const trimmedFullName = fullName.trim();
  const splitName = trimmedFullName.split(' ');
  const firstName = splitName.shift();
  const lastName = splitName.length > 0 ? splitName.join(' ') : undefined;

  return {
    firstName,
    lastName,
  };
};

export const getEoiFormattedUserInfo = () => {
  const currentUser = user.get();
  const { id, email_address: emailAddress, full_name: fullName } = currentUser;

  const splitName = getSplitName(fullName);

  return {
    id,
    emailAddress,
    ...splitName,
  };
};

export const getUserInputfromUserInfo = () => {
  const userInfo = getEoiFormattedUserInfo();
  const { firstName, lastName, emailAddress } = userInfo;

  return {
    ...(firstName && {
      firstName: {
        [SHORT_ANSWER_TYPE]: {
          text: firstName,
        },
      },
    }),
    ...(lastName && {
      lastName: {
        [SHORT_ANSWER_TYPE]: {
          text: lastName,
        },
      },
    }),
    emailAddress: {
      [SHORT_ANSWER_TYPE]: {
        text: emailAddress,
      },
    },
  };
};

export const getInputfromUserProfile = async () => {
  const { userProfile } = await getUserProfile({
    userId: user.get().id,
  });
  const { phone } = userProfile;
  let phoneNumberInput = {} as PhoneNumberInput | {};

  if (phone) {
    phoneNumberInput = getPhoneNumberInput(phone) as PhoneNumberInput;
  }

  return {
    ...phoneNumberInput,
  };
};

export const getUserInfoFromEoiResponse = (response: SurveyResponse) => {
  let fullName = user.get().full_name;
  let phoneNumber = {};

  if (response.firstName && response.lastName) {
    const firstName = response.firstName[SHORT_ANSWER_TYPE]?.text;
    const lastName = response.lastName[SHORT_ANSWER_TYPE]?.text;
    fullName = `${firstName} ${lastName}`;
  }

  if (response.phoneNumber) {
    phoneNumber = getPhoneNumberFormat(response.phoneNumber[SHORT_ANSWER_TYPE].text);
  }

  return {
    fullName,
    ...phoneNumber,
  };
};

export const getChiliPiperUserInfo = (response: SurveyResponse) => {
  const userInfo = getEoiFormattedUserInfo();
  const { firstName, lastName, emailAddress } = userInfo;
  let phoneNumber = '';

  const ensuredFirstName = firstName ?? response.firstName[SHORT_ANSWER_TYPE]?.text;
  const ensuredLastName = lastName ?? response.lastName[SHORT_ANSWER_TYPE]?.text;

  // Just need the string, having a full object is too complicated for Chili Piper
  if (response.phoneNumber) {
    phoneNumber = response.phoneNumber[SHORT_ANSWER_TYPE]?.text;
  }

  return {
    FirstName: ensuredFirstName,
    LastName: ensuredLastName,
    Email: emailAddress,
    PhoneNumber: phoneNumber,
  };
};

export default { getEoiFormattedUserInfo, getUserInputfromUserInfo, getUserInfoFromEoiResponse, getChiliPiperUserInfo };
