/* @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { Typography2, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import type { ProductOfferingCardProps } from 'bundles/premium-hub/types';

const styles = {
  productOfferingCardMobile: (theme: Theme) => ({
    padding: theme.spacing(24, 0),
  }),
  partnerLogo: (theme: Theme) => ({
    maxHeight: '96px',
    borderRadius: '5px',
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.13)',
    [`${theme.breakpoints.down('sm')}`]: {
      maxHeight: '64px',
    },
  }),
  partnerText: (theme: Theme) => ({ marginBottom: theme.spacing(8) }),
  programDescription: (theme: Theme) => ({
    flexGrow: 2,
    paddingRight: theme.spacing(12),
  }),
  programDescriptionWithLogo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  programDetails: (theme: Theme) => ({ marginBottom: theme.spacing(8) }),
  programDeadline: (theme: Theme) => ({
    marginTop: theme.spacing(24),
  }),
  linkText: (theme: Theme) => ({
    marginTop: theme.spacing(24),
    color: theme.palette.blue[600],
    ':hover': {
      textDecoration: 'underline',
    },
  }),
};

const ProductOfferingCardMobile: React.FunctionComponent<ProductOfferingCardProps> = ({
  title,
  partnerInfo,
  programDetails,
  programSummary,
  linkText,
  programDeadlineText,
}) => {
  const theme = useTheme();

  return (
    <div css={styles.productOfferingCardMobile(theme)}>
      <div css={styles.programDescriptionWithLogo}>
        <div css={styles.programDescription(theme)}>
          <Typography2 variant="subtitleMedium" component="p">
            {title}
          </Typography2>
          <Typography2 variant="bodySecondary" component="p" css={styles.partnerText(theme)}>
            {partnerInfo?.partnerText}
          </Typography2>
          <Typography2 variant="subtitleMedium" component="p" css={styles.programDetails(theme)}>
            {programDetails}
          </Typography2>
        </div>
        {partnerInfo?.partnerLogo && partnerInfo?.partnerName && (
          <div>
            <img src={partnerInfo.partnerLogo} alt={partnerInfo.partnerName} css={styles.partnerLogo(theme)} />
          </div>
        )}
      </div>
      {programSummary && (
        <Typography2 variant="bodySecondary" component="p">
          {programSummary}
        </Typography2>
      )}
      {programDeadlineText && (
        <Typography2 variant="subtitleMedium" component="p" css={styles.programDeadline(theme)}>
          {programDeadlineText}
        </Typography2>
      )}
      <Typography2 variant="subtitleMedium" component="p" css={styles.linkText(theme)}>
        {linkText}
      </Typography2>
    </div>
  );
};

export default ProductOfferingCardMobile;
