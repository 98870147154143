import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('select-field', [
  'formValidationLabel',
  'formSupportText',
  'readOnly',
  'root',
]);

const getSelectFieldCss = css`
  .${classes.root} {
    margin-top: var(--cds-spacing-100);
  }

  .${classes.readOnly} {
    background-color: var(--cds-color-neutral-background-primary-weak);
  }

  .${classes.formValidationLabel} {
    margin-top: var(--cds-spacing-100);

    & + .${classes.root} {
      margin-top: var(--cds-spacing-50);
    }
  }

  .${classes.formSupportText} {
    margin: var(--cds-spacing-50) 0 0;
  }
`;

export default getSelectFieldCss;
